<template>
  <div :id="wrapperId" class="assign-logs-wrapper">
    <!-- Prompt text on the left of the div. -->
    <p v-if="!logs.length" class="select-logs-prompt">Please select log(s)</p>
    <p v-else class="select-logs-prompt">
      <span class="logs-count">{{ logs.length }}</span> {{ logs.length > 1 ? 'logs' : 'log' }} selected
    </p>


    <!-- The dropdown toggle -->
    <div class="log-options">

      <!-- Placeholder/Chosen option -->
      <div @click="toggleDropdownList()" :class="[{'active-dropdown': isDropdownDisplayed}]" class="dropdown-box">
        <p class="dropdown-placeholder select-placeholder">Bulk Actions</p>

        <!-- Dropdown icon -->
        <img :src="dropdownIcon" class="caret-icon" alt="caret"/>
      </div>

      <!-- The dropdown with the options -->
      <div v-if="isDropdownDisplayed" class="dropdown-list order-list">

        <!-- Order assignment -->
        <!-- The user should not be to move the log(s) to a different order if the order is NOT complete. -->
        <div v-if="hasOrderAssignment">
          <p class="dropdown-section-title">{{ assignText }}</p>
          <!-- Processed -->
          <SelectOrderOption v-if="hasProcessedOption"
                             :option="processHoursOption"
                             :custom-text="processHoursOption.type"
                             @assign="() => processHours()"/>

          <SelectOrderOption v-for="order in orders"
                             :key="order.id"
                             :option="order.orderType"
                             :is-selected="order.id === selectedOption.id"
                             :custom-text="order.orderTypeAndTitle"
                             @assign="() => onLogAssignment(order)"
                             @clicked="selectOption"/>

          <!-- Un-assign logs from the order -->
          <SelectOrderOption v-if="hasBackToUnassigned"
                             :option="unassignFromOrder"
                             :custom-text="unassignFromOrder.type"
                             @assign="() => onLogsUnAssignment()"/>

          <!-- Create new order button -->
          <p @click="toggleCreateOrderModal()" class="create-new-order">
            <img src="../../../assets/icons/svg/ic_add_secondary.svg" class="add-icon" alt="add icon"/>
            Create new order
          </p>

          <div class="divider-line actions-divider"></div>
        </div>

        <!-- Ratio edit -->
        <!-- The user should not be to edit the ratio of the log(s) if the order is NOT complete. -->
        <div v-if="hasEditRatio">
          <p class="dropdown-section-title">EDIT RATIO</p>
          <div :class="{'ratio-inactive': !newRatio}" class="ratio-action-wrapper">
            <BasicInput @on-input="(input) => newRatio = input"
                        :debounce="0"
                        type="text"
                        id="ratio"
                        name="ratio"
                        placeholder="New ratio (e.g. 80)"/>
            <DButton @click.native="applyRatioChange"
                     :disabled="isRatioInvalid"
                     text="Apply"
                     type="button"
                     class="button-main button-apply"/>
          </div>
        </div>

        <!-- Export preset -->
        <div v-if="hasExportPreset" class="export-options-wrapper">
          <div class="divider-line actions-divider"></div>
          <p class="dropdown-section-title">EXPORT PRESET</p>
          <ExportPreset @preset-updated="onExportPresetUpdate"
                        @specify-reason="toggleSpecifyExportPresetReason"
                        :is-dropdown="false"
                        :row-ids="logs.map(log => log.id)"
                        ref="exportPresetModal"/>
        </div>
      </div>
    </div>

    <!-- Create new order modal -->
    <CreateOrderModal v-if="activeModal === MODAL_NAMES.CREATE_ORDER" @order-created="refreshData"/>

    <!-- Specify reason for ratio change -->
    <SpecifyReason v-if="isSpecifyReasonModalDisplayed"
                   @reason-confirmed="onReasonConfirmed"
                   :type-of-change="typeOfChange"
                   :new-value="newValueOfChange"
                   :logs="logs"/>
  </div>
</template>

<script>
import DButton from "@/components/elements/DButton";
import {computed, ref} from "@vue/composition-api";
import SelectOrderOption from "@/components/partials/order/SelectOrderOption";
import {ASSIGN_LOGS} from "@/store/order/actions";
import CreateOrderModal from "@/components/partials/order/CreateOrderModal";
import {MODAL_CONTENT, MODAL_NAMES} from "@/models/ModalContent";
import BasicInput from "@/components/elements/BasicInput";
import {ADJUST_RATIOS, MARK_LOGS_AS_PROCESSED} from "@/store/log/actions";
import {addListener, hasParentWithMatchingSelector, isPositiveNumber, removeListener} from "@/utils/globals";
import ExportPreset from "@/components/partials/export/ExportPreset";
import {UNASSIGN_LOGS} from "../../../store/log/actions";
import OrderTypeOption, {ORDER_TYPE_IDS} from "../../../models/order/OrderTypeOption";
import SpecifyReason from "@/components/partials/reasons/SpecifyReason";
import {TYPE_OF_CHANGE} from "@/models/reasons/Reason";
import {isChange, REASONS_MODAL_IDS, reasonSetup} from "@/utils/helpers/reasonsHelper";
import {getModalHelpers} from "../../../composables/modalHelper";
import getOrderHelpers from "../../../composables/order/getOrderHelpers";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";

const icons = {
  active: require('../../../assets/icons/svg/ic_dropdown_active.svg'),
  inactive: require('../../../assets/icons/svg/ic_dropdown_inactive.svg')
}
export default {
  name: "BulkActions",
  components: {
    SpecifyReason,
    ExportPreset,
    BasicInput,
    CreateOrderModal,
    SelectOrderOption,
    DButton,
  },
  props: {
    logs: {
      type: Array,
      required: true
    },
    assignText: {
      type: String,
      required: false,
      default: "ASSIGN"
    },
    hasExportPreset: {
      type: Boolean,
      required: false,
      default: false
    },
    hasBackToUnassigned: {
      type: Boolean,
      required: false,
      default: false
    },
    hasEditRatio: {
      type: Boolean,
      required: false,
      default: true
    },
    hasOrderAssignment: {
      type: Boolean,
      required: false,
      default: true
    },
    hasProcessedOption: {
      type: Boolean,
      required: false,
      default: false
    },
  },
  emits: ['logs-assigned', 'logs-unassigned', 'refresh', 'ratios-updated', 'preset-updated'],
  setup(props, {root, emit}) {
    const store = root.$store;

    // Get URL params.
    const {projectId} = root.$route.params;

    /** Emits **/
    function logsAssigned(order) {
      ToastHandler.addNew(TOAST_CONTENTS.LOGS_ASSIGNED(props.logs.length, order, projectId));
      const orderType = order.orderType;
      emit('logs-assigned', {orderType: orderType, orderName: order.title, orderId: order.id});
    }

    function markedAsProcessed() {
      ToastHandler.addNew(TOAST_CONTENTS.MARKED_PROCESSED(props.logs.length, projectId));
      emit('marked-processed', {logCount: props.logs.length});
    }

    const logsUnAssigned = () => {
      ToastHandler.addNew(TOAST_CONTENTS.LOGS_UNASSIGNED);
      emit('logs-unassigned');
    };

    const ratiosUpdated = () => {
      ToastHandler.addNew(TOAST_CONTENTS.RATIO_UPDATED(props.logs.length, newRatio.value));
      emit('ratios-updated', {ratio: newRatio.value, logCount: props.logs.length});
    };
    const refreshData = () => emit('refresh');

    /** Orders **/
    const orders = computed(() => store.getters.getSelectedProject.ongoingOrders);
    const orderDetails = computed(() => store.getters.getSelectedOrder);
    const isOrderCompleted = computed(() => orderDetails.value?.completed);

    /** Order types **/
    const {getOrderTypes} = getOrderHelpers();
    getOrderTypes();

    /** Select **/
    const selectedOption = ref({});

    function selectOption(option) {
      selectedOption.value = option;
    }

    /** Dropdown list **/
    const isDropdownDisplayed = ref(false);

    function toggleDropdownList() {
      // The user should be able to toggle the dropdown only if there are selected logs.
      if (props.logs.length) {
        isDropdownDisplayed.value = !isDropdownDisplayed.value;
      }
      setClickListener();
    }

    /** Assign **/
    function onLogAssignment(order) {
      event.stopPropagation(); // Fire the click only for the assign button and not the parent div.

      // Get the ID of the selected order.
      const orderId = order.id;

      // Assign the logs to the order and close the actions.
      assignLogs(orderId).then(response => {
        if (response) {
          toggleDropdownList();
          logsAssigned(order);
        }
      });
    }

    function assignLogs(orderId) {
      return store.dispatch(ASSIGN_LOGS, {logs: props.logs, orderId});
    }

    /** Un-assign **/
    const unassignFromOrder = OrderTypeOption.parseDataFromObject({
      id: ORDER_TYPE_IDS.UNASSIGN,
      type: 'Back to unassigned'
    });

    function onLogsUnAssignment() {
      unassignLogs().then(response => {
        if (response) {
          toggleDropdownList();
          logsUnAssigned();
        }
      })
    }

    function unassignLogs() {
      return store.dispatch(UNASSIGN_LOGS, props.logs);
    }

    /** Ratio edit **/
    const newRatio = ref('');

    // Upon first load, the ratio is null and we do not want it to be validated.
    // In case the value is not null, the new ratio is invalid in case the number is not positive and a valid number.
    const isRatioInvalid = computed(() => newRatio.value === null ? false : (!parseInt(newRatio.value) || !isPositiveNumber(newRatio.value)));

    function applyRatioChange() {
      saveValueAndSpecifyReason(newRatio.value, null, TYPE_OF_CHANGE.RATIO);
      displaySpecifyReasonModal(null, adjustRatios, REASONS_MODAL_IDS.ASSIGN_LOGS); // Display the specify reason modal.
    }

    /** Dropdown icon **/
    const dropdownIcon = computed(() => isDropdownDisplayed.value ? icons.active : icons.inactive);

    /** Create order **/
    function toggleCreateOrderModal() {
      setModal(MODAL_CONTENT.CREATE_ORDER());
    }

    /** Ratio **/
    function adjustRatios(reason = "") {
      const logs = props.logs.map(log => {
        return {id: log.id, ratio: Number(newRatio.value)}
      });

      adjustLogsRatio(logs, reason).then(response => {
        if (response) {
          ratiosUpdated();
          toggleDropdownList();
          closeModal();
        }
      });
    }

    function adjustLogsRatio(logs, reason) {
      return store.dispatch(ADJUST_RATIOS, {logs, ratio: newRatio.value, excludeReason: reason});
    }

    /** Processed hours **/
    const processHoursOption = OrderTypeOption.parseDataFromObject({
      id: ORDER_TYPE_IDS.MARK_AS_PROCESSED,
      type: 'Processed hours'
    })

    async function processHours() {
      const marked = await store.dispatch(MARK_LOGS_AS_PROCESSED, props.logs);
      if (marked) {
        toggleDropdownList();
        markedAsProcessed();
      }
    }

    /** Update the export preset **/
    const exportPresetModal = ref(null);

    function toggleSpecifyExportPresetReason(preset) {
      saveValueAndSpecifyReason(preset.text, null, TYPE_OF_CHANGE.EXPORT_PRESET);
      displaySpecifyReasonModal(null, saveSelectedPreset, REASONS_MODAL_IDS.ASSIGN_LOGS); // Display the 'specify reason' modal.
    }

    function saveSelectedPreset(reason = "") {
      exportPresetModal.value.selectPreset(reason);
    }

    function onExportPresetUpdate() {
      emit('preset-updated');
      toggleDropdownList();
      closeModal();
    }

    /** Modal **/
    const {activeModal, closeModal, setModal} = getModalHelpers();

    /** Reason for changing a value (ratio, export preset) **/
    const isSpecifyReasonModalDisplayed = computed(() => activeModal.value === MODAL_NAMES.SPECIFY_CHANGE_REASON(REASONS_MODAL_IDS.ASSIGN_LOGS));
    const {newValueOfChange, typeOfChange, saveValueAndSpecifyReason, displaySpecifyReasonModal} = reasonSetup();

    function onReasonConfirmed(reason) {
      if (isChange(typeOfChange.value, TYPE_OF_CHANGE.RATIO)) {
        adjustRatios(reason);
      } else if (isChange(typeOfChange.value, TYPE_OF_CHANGE.EXPORT_PRESET)) {
        saveSelectedPreset(reason);
      }
    }

    /** UI **/
    const wrapperId = 'assignedLogsWrapper';

    const clickEvent = (event) => {
      // The user did not click on the bulk actions' dropdown, did not click on the specify-reason modal and did not select an option from the dropdown (specify reason).
      // The last 2 were added to make sure that the bulk actions do not close.
      if (!hasParentWithMatchingSelector(event.target, `#${wrapperId}`) && !hasParentWithMatchingSelector(event.target, 'specify-reason-wrapper') && !event.target.classList.contains('option-text')) {
        toggleDropdownList();
      }
    }

    function setClickListener() {
      // If the bulk actions dropdown is open, we need to listen to the clicks of the user in order to keep it open or close it.
      isDropdownDisplayed.value ? addListener('click', clickEvent) : removeListener('click', clickEvent);
    }

    return {
      wrapperId,

      /** Emits **/
      refreshData,

      /** Orders **/
      orders,
      isOrderCompleted,

      /** Select **/
      selectedOption,
      selectOption,

      /** Dropdown list **/
      isDropdownDisplayed,
      toggleDropdownList,

      /** Assign **/
      onLogAssignment,

      /** Un-assign **/
      onLogsUnAssignment,
      unassignFromOrder,

      /** Ratio edit **/
      TYPE_OF_CHANGE,
      newRatio,
      isRatioInvalid,
      applyRatioChange,

      /** Dropdown icon **/
      dropdownIcon,

      /** Create order **/
      toggleCreateOrderModal,

      /** Ratio **/
      adjustRatios,

      /** Processed hours **/
      processHoursOption,
      processHours,

      /** Update the export preset **/
      exportPresetModal,
      toggleSpecifyExportPresetReason,
      onExportPresetUpdate,

      /** Modal **/
      MODAL_NAMES,
      activeModal,

      /** Reason for changing a value (ratio, export preset) **/
      REASONS_MODAL_IDS,
      isSpecifyReasonModalDisplayed,
      newValueOfChange,
      typeOfChange,
      onReasonConfirmed,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/base.dropdown";
@import "../../../assets/css/forms";

.assign-logs-wrapper {
  align-items: center;
  display: flex;
}

// Text
.select-logs-prompt {
  color: var(--gray-light-03);
  font-family: var(--open-sans);
  font-size: rem(16);
  margin-right: rem(24);

  .logs-count {
    color: var(--red-main);
    font-size: rem(17);
    font-weight: bold;
    line-height: rem(20);
  }
}

// Order selection
.log-options {
  position: relative;
  width: rem(211);

  .select-placeholder {
    font-family: var(--open-sans);
  }
}

.create-new-order {
  @include hover-active-pointer();
  align-items: center;
  display: flex;
  font-weight: bold;
  padding: rem(15) rem(15) 0 rem(12);

  .add-icon {
    height: rem(16);
    margin-right: rem(6);
    width: rem(16);
  }
}

// Dropdown list
.order-list {
  @include position(absolute, $top: calc(100% + 8px), $right: 0, $left: unset);
  padding: rem(16) rem(8) rem(32) rem(8);
  width: rem(360);

  .button-create {
    margin-top: rem(32);
    width: 100%;
  }
}

.actions-divider {
  margin-bottom: rem(32);
  margin-top: rem(32);
}

.dropdown-section-title {
  color: var(--gray-light-03);
  font-size: rem(17);
  font-weight: bold;
  line-height: rem(20);
  margin: 0 rem(0) rem(16) rem(16);
  text-align: left;

  &:not(:first-of-type) {
    margin-top: rem(24);
  }
}

// Ratio edit
.ratio-action-wrapper {
  display: flex;
  padding: 0 rem(16);

  .button-apply {
    flex-basis: 83px;
    flex-shrink: 0;
    margin-left: rem(8);
  }
}

.ratio-inactive {

  .button-apply {
    background-color: #F4F5F6;
    color: var(--gray-light-03);
  }
}

// Export
.export-options-wrapper {
  padding-left: rem(16);
  padding-right: rem(8);

  .dropdown-section-title {
    margin-left: 0;
  }

  .action-button {
    width: 100%;

    &:not(:last-child) {
      margin-bottom: rem(8);
    }
  }
}
</style>
