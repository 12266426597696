<template>
  <div class="filters-box">

    <!-- Filters -->
    <div class="filters-inner">
      <!-- Date range -->
      <DateRange v-if="hasDatePicker" class="filter-element" ref="dateFilter"/>

      <!-- Issue -->
      <SearchForJiraIssue @issue-selected="onIssueSelection" class="filter-element" ref="issueSearchFilter"/>

      <!-- Select employee -->
      <SearchForEmployee @employee-selected="onEmployeeSelection" class="filter-element" ref="employeeSearchFilter"/>

      <!-- Discipline -->
      <Discipline @department-selected="onDepartmentSelection" class="filter-element" ref="disciplineFilter"/>
    </div>

    <!-- Clear all button -->
    <DButton @click.native="clearFilters()"
             text="Clear all"
             :icon="require('../../../assets/icons/svg/ic_close_secondary.svg')"
             type="button"
             class="button-border-black button-clear"/>
  </div>
</template>

<script>
import {ref} from "@vue/composition-api";
import DButton from "@/components/elements/DButton";
import Discipline from "@/components/partials/filters/Discipline";
import SearchInputField from "@/components/partials/search/SearchInputField";
import SearchResult from "@/components/partials/search/SearchResult";
import SearchForEmployee from "../search/SearchForEmployee";
import SearchForJiraIssue from "@/components/partials/search/SearchForJiraIssue";
import DateRange from "@/components/partials/filters/DateRange";
import FilterHandler from "@/utils/filters/FilterHandler";

export default {
  name: "Filters",
  components: {
    DateRange,
    SearchForJiraIssue,
    SearchForEmployee,
    SearchResult,
    SearchInputField,
    Discipline,
    DButton
  },
  // This component's children DateRange, SearchForJiraIssue, SearchForEmployee and Discipline
  // all fire events directly to the parent of this component.

  props: {
    hasDatePicker: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  emits: ['filters-changed'],
  setup(props, {emit}) {
    const dateFilter = ref(null);
    const employeeSearchFilter = ref(null);
    const issueSearchFilter = ref(null);
    const disciplineFilter = ref(null);

    /** Clear **/
    function clearFilters() {
      if (props.hasDatePicker) {
        dateFilter.value.clear();
      }
      employeeSearchFilter.value.resetFilter();
      issueSearchFilter.value.resetFilter();
      disciplineFilter.value.resetFilter();

      // New update.
      FilterHandler.reset();
    }

    /** Filters **/
    function onEmployeeSelection(employees) {
      FilterHandler.setFilter('employeeIds', employees.map(employee => employee.id))
      emit('filters-changed');
    }


    function onIssueSelection(issues) {
      FilterHandler.setFilter('issueIds', issues.map(issue => issue.id));
      emit('filters-changed');
    }

    function onDepartmentSelection(departments) {
      FilterHandler.setFilter('departments', departments.map(department => department.name));
      emit('filters-changed');
    }

    return {
      dateFilter,
      employeeSearchFilter,
      issueSearchFilter,
      disciplineFilter,

      /** Clear **/
      clearFilters,

      /** Filters **/
      onEmployeeSelection,
      onIssueSelection,
      onDepartmentSelection,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/base.dropdown";

.filters-box {
  display: flex;

  .filters-inner {
    display: grid;
    flex-grow: 1; // Expand to the fullest available space.
    grid-auto-columns: minmax(0, 1fr);
    grid-auto-flow: column;
    grid-gap: rem(16);
    margin-right: rem(23);

    .filter-element {
    }
  }

  .button-clear {
    align-items: center;
    display: flex;
    flex-basis: rem(129);
    flex-shrink: 0;
    font-weight: normal;
    height: rem(42);
    margin-top: auto;
  }
}

//--------------------------------------------------------
// Filter items
//--------------------------------------------------------
.select-employees, .select-issues {
  position: relative;

  .search-element {
    height: 100%;
  }

  .search-results {

    .selected-items {
      margin-top: rem(32);

      .selected-text {
        font-size: rem(14);
        font-weight: bold;
        line-height: rem(19);
        margin-bottom: rem(15);
      }
    }

    .result-item {
      &:not(:last-child) {
        margin-bottom: rem(8);
      }
    }
  }
}

// Custom
.unassigned-logs-filters {
  &::v-deep .date-range-wrapper {

    .pickers-box {
      display: flex;
      position: relative;

      .date-input {
        height: rem(42);
        width: 50%;
      }


      &::v-deep .date-range-wrapper, &::v-deep .picker-input {
        height: 100%;
        width: 100%;
      }

      &::v-deep .ratio-date-picker input {
        width: 100%;
      }
    }
  }
}
</style>
