<template>
  <div :id="disciplineFilterId" class="dropdown-wrapper discipline-filter">
    <p class="filter-title">Discipline</p>

    <div @click="toggleOptions()" :class="{'active-dropdown': areOptionsDisplayed}" :id="disciplineBoxId"
         class="dropdown-box-42 filter-preview">

      <!-- If there are no selected options, display the placeholder. -->
      <p v-if="!selectedDepartments.length" class="dropdown-placeholder">Pick a discipline</p>

      <!-- Filters -->
      <div :class="{'overflowing-wrapper': filtersOverflow}" class="filters-wrapper">
        <div v-for="(filter, index) in selectedDepartments"
             :key="filter.id"
             :class="{'filter-chip-overflowing': index + 1 > filtersInPreview}"
             class="selected-filter-chip">
          <p class="text">{{ filter.name }}</p>
          <img src="../../../assets/icons/svg/ic_close_secondary.svg" class="icon" alt="close"/>
        </div>
      </div>

      <!-- Info and action -->
      <div :id="infoActionDisciplineId" class="info-action">

        <!-- If not all the selected filters are shown in the preview, an indication of how many are not shown is displayed to the user. -->
        <span v-if="filtersOverflow" class="more">{{ selectedDepartments.length - filtersInPreview }} more</span>
        <img :src="dropdownIcon" class="filter-caret-icon" alt=""/>
      </div>
    </div>

    <div v-if="areOptionsDisplayed && departments.length"
         :class="{'has-selected-items': selectedDepartments.length}"
         :id="disciplinePanelId"
         class="options-wrapper dropdown-list">

      <!-- Selected option(s) -->
      <div class="selected-options">
        <Tag v-for="option in selectedDepartments"
             :key="option.id"
             :is-selected="true"
             :name="option.name"
             :icon="require('../../../assets/icons/svg/ic_close_secondary.svg')"
             @click.native="removeOption(option)"
             class="option"/>
      </div>

      <!-- Available option(s) -->
      <div class="available-options">
        <div v-for="option in departments" :key="option.id" class="tag-wrapper">
          <Tag v-if="selectedDepartments.findIndex(item => option.id === item.id) === -1"
               :name="option.name"
               :icon="require('../../../assets/icons/svg/ic_add_dark_grey.svg')"
               @click.native="selectOption(option)"
               class="option"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {ref, computed, watch} from "@vue/composition-api";
import Tag from "@/components/elements/filters/Tag";
import {GET_DEPARTMENTS} from "@/store/jira/actions";
import {addListener, hasParentWithMatchingSelector, removeListener} from "@/utils/globals";

export default {
  name: "Discipline",
  components: {
    Tag
  },
  emits: ['department-selected'],
  setup(props, {root, parent, emit}) {
    const store = root.$store;

    // Static ids.
    const disciplineBoxId = 'disciplineBox';
    const infoActionDisciplineId = 'infoActionDiscipline';
    const disciplinePanelId = 'disciplinePanel';
    const disciplineFilterId = 'disciplineFilter';

    /** Departments **/
    const departments = computed(() => store.getters.getDepartments);
    getDepartments()

    function getDepartments() {
      return store.dispatch(GET_DEPARTMENTS);
    }

    /** Options **/
    const areOptionsDisplayed = ref(false);

    function toggleOptions() {
      areOptionsDisplayed.value = !areOptionsDisplayed.value;

      // If the dropdown option list is displayed, the click event should be listened to.
      setClickListener();
    }

    /** Selected **/
    const selectedDepartments = ref([]);

    function selectOption(option) {
      // Add the option to the selected options.
      selectedDepartments.value.push(option);

      // Inform the parent that an item has been selected.
      updateParent();

      // Update the preview area with the latest items.
      calculatePreviewItems()
    }

    function removeOption(option) {
      event.stopPropagation(); // Prevent the parent from triggering the click event.

      const index = selectedDepartments.value.findIndex(item => item.id === option.id);

      // Add or remove the department, depending on the index.
      if (index === -1) {
        selectedDepartments.value.push(option);
      } else {
        selectedDepartments.value.splice(index, 1);
      }

      updateParent();
    }

    /** Dropdown icon **/
    const dropdownIcon = computed(() => areOptionsDisplayed.value ? require('../../../assets/icons/svg/ic_dropdown_active.svg') : require('../../../assets/icons/svg/ic_dropdown_inactive.svg'));

    /** Reset **/
    function resetFilter() {
      selectedDepartments.value = [];

      // Inform the parent about the new value of the selectedIssues.
      updateParent();
    }

    function updateParent() {
      parent.$emit('department-selected', selectedDepartments.value);
      emit('department-selected', selectedDepartments.value);
    }

    /** Filter preview **/
    const filtersInPreview = ref(0); // Saves the amount of departments that are should be displayed in the preview.

    // Saves if the amount of filters that are displayed in the filter preview are overflowing (do not fit in the wrapper).
    const filtersOverflow = computed(() => selectedDepartments.value.length > filtersInPreview.value);

    function calculatePreviewItems() {
      root.$nextTick(() => {
        let filtersWidth = 0;

        // Reset the saved value from the previous time the value was updated.
        filtersInPreview.value = 0;

        // Get the width of the filter preview wrapper.
        const previewWrapper = document.getElementById(disciplineBoxId);
        const previewWrapperOffsetWidth = previewWrapper.offsetWidth;

        // Get the width of the info action wrapper.
        const infoAction = document.getElementById(infoActionDisciplineId);
        const infoActionWidth = infoAction.offsetWidth;

        // Get the width of the available space, not forgetting the margin-right value of the selected-filter-chip class.
        const availableSpace = previewWrapperOffsetWidth - (infoActionWidth + 8); // 8 = margin-right

        // Get the width of each selected item to calculate how many fit in the wrapper.
        const allFilters = document.querySelectorAll('.selected-filter-chip');

        // For each filter chip, check if it can fit in the filter box.
        allFilters.forEach((element, index) => {

          // if the element can fit in the filter box, make sure to update the number of filtersInPreview and the total width of the displayed filter items.
          if (filtersWidth + element.offsetWidth <= availableSpace) {
            filtersInPreview.value++;
            filtersWidth += element.offsetWidth;
          }
        });
      });
    }

    watch(areOptionsDisplayed, (newVal) => {
      // As soon as the results are hidden and there are selected filters, we need to set up the filter preview box.
      if (!newVal && selectedDepartments.value.length > 0) {
        calculatePreviewItems();
      }
    });

    /** Click listeners **/
    function setClickListener() {
      // If the search results panel is open, we need to listen to the clicks of the user in order to keep open or close the search results and make the input inactive.
      areOptionsDisplayed.value ? addListener('click', clickEvent) : removeListener('click', clickEvent);
    }

    const clickEvent = (event) => {
      const parentNode = event.target.parentNode; // Get the parent node of the clicked element.
      const isResultItem = parentNode.classList.contains('option'); // Check if the user clicked on a result item.

      const clickedOnWrapper = !hasParentWithMatchingSelector(event.target, `#${disciplineFilterId}`);
      const clickedOnPanel = !hasParentWithMatchingSelector(event.target, `#${disciplinePanelId}`);
      // const clickedOnDisciplineBox = !hasParentWithMatchingSelector(event.target, `#${disciplineBoxId}`)

      // If the user clicked on a discipline item, the panel or the toggle, then the panel should remain visible
      if (!isResultItem && clickedOnPanel && clickedOnWrapper) {
        toggleOptions();
      }
    }

    return {
      // Static IDs
      disciplineBoxId,
      infoActionDisciplineId,
      disciplinePanelId,
      disciplineFilterId,

      /** Departments **/
      departments,

      /** Options **/
      areOptionsDisplayed,
      toggleOptions,

      /** Selected **/
      selectedDepartments,
      selectOption,
      removeOption,

      /** Dropdown icon **/
      dropdownIcon,

      /** Reset **/
      resetFilter,

      /** Filter preview **/
      filtersInPreview,
      filtersOverflow,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/base.dropdown";
@import "../../../assets/css/filters";

.discipline-filter {
}

.options-wrapper {

  &.has-selected-items {

    .available-options {
      margin-top: rem(32);
    }
  }
}

// Selected option(s)
.selected-options {
  display: flex;
  flex-wrap: wrap;

  .option {
    margin-bottom: rem(8);
  }
}

// Available option(s)
.available-options {
  display: flex;
  flex-wrap: wrap;

  .tag-wrapper {

    .option {
      margin-bottom: rem(8);
    }

    &:not(:last-child) {
      .option {
        margin-right: rem(8);
      }
    }
  }
}
</style>
