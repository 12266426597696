import {ref} from "@vue/composition-api";
import {MODAL_CONTENT} from "@/models/ModalContent";
import {SET_MODAL_OPTIONS} from "@/store/mutations";
import vuexStore from "@/store";
import {TYPE_OF_CHANGE} from "@/models/reasons/Reason";

export const REASONS_MODAL_IDS = {
  // Unassigned logs
  LOGS_TABLE: 'LogsTable',

  // Order
  ORDER_LOGS: 'OrderLogs',

  // Shared
  ASSIGN_LOGS: 'AssignLogs',
}

export const isChange = (reason, value) => reason === value; // Checks if the reason is specific value.

export function reasonSetup() {
  const newValueOfChange = ref(null); // Represents the newValue for the SpecifyReason component.
  const typeOfChange = ref(''); // TYPE_OF_CHANGE
  const activeRow = ref(null); // The row in which the change just took place (only for single edit).

  // Sets the value of the change.
  const setNewValueOfChange = (value) => newValueOfChange.value = value;

  // Sets the type of the change.
  const setTypeOfChange = (value) => typeOfChange.value = value;

  /**
   * Displays the 'Specify reason' modal, passing necessary callbacks and options to the store.
   * @param {Function} callback - Function to be called upon clicking the 'execute' button in the modal.
   * @param {Function} cancelCallback - Function to be called when clicking the 'cancel' button in the modal.
   * @param {String} modalId - The ID for the modal, since there can be multiple 'Specify reason' modals.
   */
  function displaySpecifyReasonModal(callback, cancelCallback, modalId) {
    const modalContent = MODAL_CONTENT.SPECIFY_CHANGE_REASON(callback, cancelCallback, modalId);
    vuexStore.commit(SET_MODAL_OPTIONS, {modalContent: modalContent, activeModal: modalContent.name});
  }

  function saveValueAndSpecifyReason(value, row, type) {
    // Set the active row.
    if (row) {
      activeRow.value = row;
    }

    // Set the change's new value to the entered ratio.
    setNewValueOfChange(value);

    // Set the "active" reason.
    setTypeOfChange(type);

    // Display the specify reason modal.
  }

  function resetActiveRow() {
    activeRow.value = null;
  }

  // Return the necessary items.
  return {
    activeRow,
    newValueOfChange,
    typeOfChange,
    setNewValueOfChange,
    setTypeOfChange,
    saveValueAndSpecifyReason,
    displaySpecifyReasonModal,
    resetActiveRow,
  };
}
