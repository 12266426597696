export default function initializeProcessedOrderTable() {
  const tableOptions = {
    headings: {
      'checkbox': '',
      'createdAt': 'Date',
      'employee': 'Employee',
      'issueKey': 'Issue',
      'summary': 'Summary',
      'comment': 'Comment',
      'hours': 'Hours',
    },
    sortable: {},
    editable: {
      'checkbox': false,
      'createdAt': false,
      'employee': false,
      'issueKey': false,
      'summary': false,
      'comment': false,
      'hours': false,
    },
  };

  return {tableOptions};
}