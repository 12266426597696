<template>
  <div class="log-change">
    <!-- Log's title and link -->
    <p class="log-info">
      <a :href="log.issueLink" class="log-link">{{ log.issueKey }}</a>
      <span class="log-title">{{ log.issueSummary }}</span>
    </p>

    <!-- Change -->
    <div class="change-box">
      <span class="change-title">{{ changeTitle }}</span>
      <span class="change-value">{{ changeFrom }}</span>
      <img src="../../../assets/icons/svg/ic_arrow_right_secondary.svg" class="arrow" alt="arrow"/>
      <span class="change-value">{{ changeTo }}</span>
    </div>
  </div>
</template>

<script>

const changeTitles = {
  ratio: "Aspect ratio",
  exportPreset: "Export preset",
}

export default {
  name: "LogChange",
  props: {
    log: {},
    changeFrom: {},
    changeTo: {},
    reason: {}
  },
  setup(props) {
    return {
      changeTitle: changeTitles[props.reason]
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";

$box-color: #ECECEC;

.log-change {
  &:not(:last-child) {
    margin-bottom: rem(16);
  }

  .log-info {
    align-items: flex-start;
    display: flex;
    margin-bottom: rem(13);

    .log-link {
      color: var(--red-main);
      flex-shrink: 0;
      margin-right: rem(8);

      @include on-hover {
        text-decoration: underline;
      }
    }
  }

  .change-box {
    align-items: center;
    background-color: $box-color;
    border-radius: rem(6);
    display: inline-flex;
    padding: rem(9) rem(16);

    .change-title {
      margin-right: rem(10);
      opacity: 0.6;
    }

    .arrow {
      height: rem(15);
      margin-right: rem(2);
      width: rem(15);
    }

    .change-value {
      color: var(--black-light);
      font-weight: bold;
    }
  }
}
</style>
