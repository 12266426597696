<template>
  <div class="available-actions">
    <div :class="{'available-actions-fixed': isInfoFixed}" class="available-actions-inner">
      <SectionTitleWithIcon :icon="require('../../../../assets/icons/svg/ic_time_primary.svg')"
                            hasBackground
                            title="Hour logs" class="hours-title"/>

      <!-- Export button -->
      <DButton v-if="hasExport"
               @click.native="$emit('export')"
               :icon="require('../../../../assets/icons/svg/ic_download_white.svg')"
               text="Export to PDF"
               type="button"
               class="button-main button-export"/>

      <!-- Bulk actions -->
      <BulkActions :logs="logs"
                   assign-text="REASSIGN"
                   :has-back-to-unassigned="true"
                   @logs-unassigned="logsAssigned"
                   @logs-assigned="(data) => logsAssigned(data)"
                   :has-edit-ratio="false"
                   class="bulk-actions"/>
    </div>

    <!-- Observer that checks if the actions are hidden. -->
    <Observer v-if="observerOptions"
              @intersected="updateVisibility"
              :options="observerOptions"
              ref="availableHoursObserver"/>
  </div>
</template>

<script>
import SectionTitleWithIcon from "@/components/elements/SectionTitleWithIcon";
import DButton from "@/components/elements/DButton";
import BulkActions from "@/components/partials/bulkActions/BulkActions";
import {ref} from "@vue/composition-api";
import Observer from "@/components/elements/Observer";
import {onMounted} from "@vue/composition-api";
import getObserverHelpers from "@/utils/helpers/observerHelpers";

export default {
  name: "AvailableActions",
  components: {
    Observer,
    BulkActions,
    DButton,
    SectionTitleWithIcon
  },
  props: {
    logs: {
      type: Array,
      required: true
    },
    hasExport: {
      type: Boolean,
      required: false,
    }
  },
  emits: ['export', 'logs-assigned'],
  setup(props, {root, emit}) {
    const logsAssigned = (data) => emit('logs-assigned', data);

    /** Observer - Used to style the component, when it is scrolled out of the viewport. **/
    const {observerOptions, initializeObserver} = getObserverHelpers();
    const isInfoFixed = ref(false);

    function updateVisibility(isVisible) {
      isInfoFixed.value = !isVisible;
    }

    const availableHoursObserver = ref(null);
    initializeObserver(availableHoursObserver);

    onMounted(() => {
      observerOptions.value = {
        rootMargin: '-210px' // Considering the height of the navigation bar (82px) and ProcessedOrderInformation (115px).
      };
    })

    return {
      logsAssigned,

      /** Available actions - Styling **/
      observerOptions,
      isInfoFixed,
      availableHoursObserver,
      updateVisibility,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../../assets/css/base.mixins";


// Available actions
.available-actions {
  .available-actions-inner {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: rem(25);
  }

  .bulk-actions {
    ::v-deep .dropdown-box {
      width: rem(211);
    }
  }
}

// Export
.button-export {
  @include position-elevation(fixed, $top: rem(110), $right: rem(40), $elevation: var(--export-button-index)); // page-content padding and margin,
  box-shadow: 2px 4px 8px 0 rgba(70, 70, 70, 0.25);
  padding: 0 rem(19);
  text-transform: uppercase;
  transition: all 0.25s ease-out;
}

/*
 * Fixed styling - Styling applied when the user has scrolled.
 */
.available-actions-fixed {

  // If the user has scrolled and the actions are fixed, the hours title should not be displayed.
  .hours-title {
    display: none;
  }

  // The bulk actions should be re-positioned.
  .bulk-actions {
    @include position-elevation(fixed, $top: rem(120), $right: rem(80), $left: rem(80), $elevation: var(--available-actions-fixed-index)); // page-content padding and margin,
    background-color: white;
    padding: rem(25) 0 rem(15) 0;

    ::v-deep .order-list {
      max-height: 65vh;
      overflow-y: auto;
    }
  }

  // Export
  .button-export {
    @include position-elevation(fixed, $top: rem(145), $right: rem(80), $elevation: var(--export-button-index));
  }
}
</style>