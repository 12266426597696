<template>

  <div id="searchEmployeeToggle" class="select-employees filter-element">
    <p class="filter-title">Employee</p>
    <FilterSearchField @search="search"
                       @clear-filter="clearSearchEmployeesResults"
                       @display-results="toggleResults"
                       preview-id="employeeFilterPreview"
                       results-panel-id="searchEmployeeList"
                       filter-wrapper-id="searchEmployeeToggle"
                       :selected-filters="selectedEmployees"
                       placeholder="e.g. Bas Kramp"
                       ref="filterSearchEmployee"
                       id="selectEmployee"/>

    <!-- The search results should be displayed if there are search results or selected items. -->
    <div v-show="areResultsDisplayed && (searchResults.length || selectedEmployees.length)"
         id="searchEmployeeList"
         class="search-results dropdown-list">

      <!-- Results - The suggestions as the user is typing. -->
      <SearchResult v-for="employee in searchResults"
                    :key="employee.id"
                    @select="() => selectEmployee(employee)"
                    :is-selected="false"
                    :item="employee.searchResultItem"
                    :icon="require('../../../assets/icons/svg/ic_add_dark_grey.svg')"
                    class="result-item employee-result"/>

      <!-- Selected -->
      <div v-if="selectedEmployees.length" class="selected-items">
        <p class="selected-text">Selected</p>
        <SearchResult v-for="employee in selectedEmployees"
                      :key="employee.id"
                      @select="() => selectEmployee(employee)"
                      :is-selected="true"
                      :item="employee.searchResultItem"
                      :icon="require('../../../assets/icons/svg/ic_close_secondary.svg')"
                      class="result-item"/>
      </div>
    </div>

  </div>
</template>

<script>
import SearchInputField from "./SearchInputField";
import SearchResult from "./SearchResult";
import {computed, ref} from "@vue/composition-api";
import {SEARCH_JIRA_EMPLOYEE} from "../../../store/jira/actions";
import {CLEAR_SEARCH_EMPLOYEES_RESULTS} from "@/store/jira/mutations";
import FilterSearchField from "@/components/partials/filters/FilterSearchField";

export default {
  name: "SearchForEmployee",
  components: {
    FilterSearchField,
    SearchResult,
    SearchInputField
  },
  props: {},
  emits: ['employee-selected'],
  setup(props, {root, emit, parent}) {
    const store = root.$store;

    /** Toggling **/
    const filterSearchEmployee = ref(null);
    const areResultsDisplayed = ref(false);

    // Gets fired from the child.
    function toggleResults() {
      areResultsDisplayed.value = !areResultsDisplayed.value;
    }

    function toggleChildResults() {
      filterSearchEmployee.value.toggleResults();
    }

    /** Search **/
    const searchEmployee = ref(null);
    const searchTerm = ref("");

    // The search results should not contain already selected employees.
    const searchResults = computed(() => jiraEmployees.value.filter(jiraEmployee => selectedEmployees.value.findIndex(item => item.id === jiraEmployee.id) === -1));

    function search(term) {
      searchTerm.value = term;
      searchJiraEmployees().then(response => {
        if (response) {
          if (!areResultsDisplayed.value) {
            toggleChildResults();
          }
        }
      })
    }

    /** Selection **/
    const selectedEmployees = ref([]);

    function selectEmployee(employee) {
      // Check if the employee already exists in the selectedEmployees array.
      const index = selectedEmployees.value.findIndex(emp => emp.id === employee.id);

      // Add or remove the employee, depending on the index.
      if (index === -1) {
        selectedEmployees.value.push(employee);
      } else {
        selectedEmployees.value.splice(index, 1);
      }

      // Inform the parent about the new value of the selectedEmployees.
      updateParent();
    }

    /** Reset **/
    function resetFilter() {
      selectedEmployees.value = [];

      // Inform the parent about the new value of the selectedIssues.
      updateParent();
    }

    function updateParent() {
      parent.$emit('employee-selected', selectedEmployees.value);
      emit('employee-selected', selectedEmployees.value);
    }

    /** Jira search **/
    const jiraEmployees = computed(() => store.getters.getSearchEmployeesResults);

    function searchJiraEmployees() {
      return store.dispatch(SEARCH_JIRA_EMPLOYEE, searchTerm.value);
    }

    function clearSearchEmployeesResults() {
      store.commit(CLEAR_SEARCH_EMPLOYEES_RESULTS)
    }

    return {

      /** Toggling **/
      toggleResults,
      filterSearchEmployee,
      areResultsDisplayed,

      /** Search **/
      searchEmployee,
      searchResults,
      search,

      /** Select employee **/
      selectedEmployees,
      selectEmployee,

      /** Reset **/
      resetFilter,
      clearSearchEmployeesResults,

      /** Jira search **/
      jiraEmployees,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/base.dropdown";
@import "../../../assets/css/forms";

.select-employees, .select-issues {
  position: relative;

  .search-element {
    height: 100%;
  }

  .employee-result:last-child {
    margin-bottom: rem(32);
  }

  .input-invisible {
    position: absolute;
    visibility: hidden;
  }

  .employee-search-inactive {
    display: flex;
    height: rem(52);
    padding: rem(10);
    position: relative;

    .caret-icon {
      @include position(absolute, $top: 50%, $right: rem(15));
      height: rem(24);
      transform: translateY(-50%);
      width: rem(24);
    }
  }
}
</style>
