<template>
  <div @click="enableEdit()" :id="employeeCellId" :class="{'active': isContentEditable}" class="editable-cell">
    <p v-if="!isContentEditable" :id="`content-${contentId}`" class="editable-area">{{ text }}</p>

    <BasicInput v-else
                @on-input="searchForEmployee"
                :value="selectedEmployee ? selectedEmployee.displayName : ''"
                type="text"
                :id="`employeeEdit-${contentId}`"
                :name="`employeeEdit-${contentId}`"
                class="editable-area simplified-basic-input"/>

    <!-- Search results -->
    <div v-if="jiraEmployees.length && isContentEditable" class="dropdown-list search-results">

      <SearchResult v-for="employee in jiraEmployees"
                    :key="employee.id"
                    @select="() => selectEmployee(employee)"
                    :is-selected="false"
                    :item="employee.searchResultItem"
                    :icon="require('../../../assets/icons/svg/ic_add_dark_grey.svg')"
                    class="result-item"/>
    </div>

    <!-- Cell actions -->
    <div v-if="isContentEditable" class="cell-actions">
      <img @click="cancelEdit()" src="../../../assets/icons/svg/ic_close_secondary.svg" class="icon" alt="cancel"/>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "@vue/composition-api";
import {hasParentWithMatchingSelector, stopPropagation} from "@/utils/globals";
import {SEARCH_JIRA_EMPLOYEE} from "@/store/jira/actions";
import {CLEAR_SEARCH_EMPLOYEES_RESULTS} from "@/store/jira/mutations";
import BasicInput from "@/components/elements/BasicInput";
import SearchResult from "@/components/partials/search/SearchResult";

export default {
  name: "EmployeeEditableCell",
  components: {
    SearchResult,
    BasicInput
  },
  props: {
    isEditSupported: {
      type: Boolean,
      required: false,
      default: true
    },
    rowId: {
      type: String,
      required: true
    },
    text: {
      type: String,
      required: true
    },
    contentId: {
      type: [Number, String],
      required: true
    }
  },
  emits: ['save', 'on-active-edit', 'on-inactive-edit'],
  setup(props, {emit, root}) {
    const store = root.$store;
    const employeeCellId = `employeeCell${props.contentId}`;
    const cellKey = 'employee';

    let editableContent = ref(null);
    const isContentEditable = ref(false);
    onMounted(() => {
      editableContent.value = document.getElementById(`content-${props.contentId}`); // Get the HTML element.
    });

    function toggleEditableContent(value) {
      isContentEditable.value = value;
      editableContent.value.contentEditable = isContentEditable.value.toString();
    }

    /** Enable content edit **/
    function enableEdit() {
      // The edit should not be enabled if it is not supported or the edit is already enabled.
      if (!props.isEditSupported || isContentEditable.value) {
        return;
      }

      // Let the parent know that the cell is now editable.
      emit('on-active-edit', {cellKey, rowId: props.rowId});

      // Enable editing on the content.
      toggleEditableContent(true);

      // Focus on the element so the user can immediately start typing.
      editableContent.value.focus();

      // Check if the user clicks outside the editable cell.
      listenToUserClick();
    }

    /** Disable content edit **/
    function disableEdit() {
      // Let the parent know that the cell is not editable anymore.
      emit('on-inactive-edit', {cellKey: null, rowId: props.rowId});

      // Disable editing on the content.
      toggleEditableContent(false);

      // Check if the user clicks outside the editable cell.
      removeUserClickListener();
    }

    /** Save edit **/
    function saveContent(employeeId) {
      // Make sure that only the saveContent function is executed.
      stopPropagation();

      // Emit the change to the parent.
      emit('save', {employeeId});

      // Disable editing on the content.
      disableEdit();

      // Clear the existing search results.
      clearSearchEmployeesResults();
    }

    /** Cancel edit **/
    function cancelEdit() {
      stopPropagation(); // Make sure that only the cancelEdit function is executed.
      editableContent.value.innerText = props.text; // Revert the content to the original.
      disableEdit(); // Disable editing on the content.
      clearSearchEmployeesResults(); // Clear the existing search results.
    }

    /** Employee search **/
    const selectedEmployee = ref(null);

    function searchForEmployee(term) {
      searchJiraEmployees(term);
    }

    function selectEmployee(employee) {
      selectedEmployee.value = employee.name;
      saveContent(employee.id);
    }

    /** Jira search **/
    const jiraEmployees = computed(() => store.getters.getSearchEmployeesResults);

    function searchJiraEmployees(term) {
      return store.dispatch(SEARCH_JIRA_EMPLOYEE, term);
    }

    function clearSearchEmployeesResults() {
      store.commit(CLEAR_SEARCH_EMPLOYEES_RESULTS)
    }

    /** User clicks **/
    function listenToUserClick() {
      document.addEventListener('click', userClickEvent);
    }

    function removeUserClickListener() {
      document.removeEventListener('click', userClickEvent);
    }

    function userClickEvent(event) {
      const clickedOnAChild = hasParentWithMatchingSelector(event.target, `#${employeeCellId}`);
      const clickedOnEditableCellWrapper = event.target.id === employeeCellId;
      const clickedOnEditableArea= event.target.id === `content-${props.contentId}`;

      // Check if the user clicked outside the editable area.
      if (!(clickedOnAChild || clickedOnEditableCellWrapper || clickedOnEditableArea)) {
        cancelEdit();
      }
    }

    return {
      employeeCellId,
      isContentEditable,

      /** Enable content edit **/
      enableEdit,

      /** Cancel edit **/
      cancelEdit,

      /** Save edit **/
      saveContent,

      /** Employee search **/
      selectedEmployee,
      selectEmployee,
      searchForEmployee,

      /** Jira search **/
      jiraEmployees,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/base.dropdown";

.editable-cell {
  @include hover-active-pointer;
  align-items: center;
  caret-color: var(--red-main);
  display: flex;
  flex-wrap: nowrap;
  position: relative;

  .editable-area {
    flex-grow: 1;

    &:focus {
      outline: 0 solid transparent;
    }
  }
}

// Results
.search-results {
  padding: rem(8);
  top: calc(100% + 25px);
  left: rem(-20);
  right: rem(-20);
}

// Cell actions
.cell-actions {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: rem(15);

  .icon {
    height: rem(24);
    width: rem(24);

    &:not(:last-child) {
      margin-right: rem(8);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

</style>
