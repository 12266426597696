export class EditableCellHandler {
  constructor() {
    this.cell = null;
  }

  updateCell(data) {
    this.cell = new EditableCell(data);
  }

  addCell(data) {
    // If the editable cell is already populated, we need to check if the new value is different or null. (No cellKey => reset value)
    if (this.cell) {
      // If there is a cellKey present, the editableCell should be updated.
      if (data.cellKey) {
        this.updateCell(data);
      }

      // If there is not a cellKey present, it means that the cell should no longer be active.
      // The cell should be cleared ONLY if the rowId of the incoming data is the same as the currently saved cell.
      else {
        if (this.cell.hasSameIdAsIncoming(data.rowId)) {
          this.clearCell();
        }
      }
    }

    // Otherwise, we can simply set the editable cell to the incoming value.
    else {
      this.cell = new EditableCell(data);
    }
  }

  clearCell() {
    this.cell = null;
  }

  static getInstance() {
    if (!this.instance) {
      this.instance = new EditableCellHandler();
    }
    return this.instance;
  }
}

class EditableCell {
  constructor({cellKey, rowId}) {
    this.cellKey = cellKey;
    this.rowId = rowId;
  }

  hasSameIdAsIncoming(incomingRowId) {
    return this.rowId === incomingRowId;
  }
}

// const editableCellHandler = EditableCellHandler.getInstance();
// export default editableCellHandler;
