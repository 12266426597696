<template>
  <div class="specify-reason-wrapper">

    <Modal @execute="confirmReason" :disabled-right-button="isConfirmationButtonDisabled">
      <template #content>

        <!-- Log(s) to be changed - It is displayed only if the change is not default. -->
        <div v-if="typeOfChange !== TYPE_OF_CHANGE.DEFAULT" class="log-changes">
          <div v-for="log in logs" :key="log.id" class="log-change">

            <!-- Log's title and link -->
            <p class="log-info">
              <DLink :text="log.issueKey" :link="log.issueLink" class="log-link"/>
              <span class="log-title">{{ log.issueSummary }}</span>
            </p>

            <!-- Change -->
            <div class="change-box">
              <span class="change-title">{{ changeTitle }}</span>
              <span v-if="isCurrentValueDisplayed" class="change-value">{{ log[typeOfChange] }}</span>
              <img src="../../../assets/icons/svg/ic_arrow_right_secondary.svg" class="arrow" alt="arrow"/>
              <span class="change-value">{{ newValue }}</span>
            </div>
          </div>
        </div>

        <!-- Reason dropdown -->
        <Dropdown @select-option="(choice) => selectedReason = choice"
                  :options="reasons"
                  option-component-name="DropdownOption"
                  placeholder="Select reason"
                  class="reason-dropdown">
          <template #placeholder="{placeholder, selectedOption}">
            <span :class="{'option-placeholder': !isObjectEmpty(selectedOption)}" class="placeholder">
              {{ isObjectEmpty(selectedOption) ? placeholder : selectedOption.text }}
            </span>
          </template>
        </Dropdown>

      </template>
    </Modal>

  </div>
</template>

<script>
import {computed, ref} from "@vue/composition-api";
import Modal from "@/components/partials/Modal";
import RatioReason from "@/models/reasons/RatioReason";
import {RATIO_REASONS} from "../../../models/reasons/RatioReason";
import Dropdown from "@/components/elements/dropdown/Dropdown";
import DropdownOption from "@/components/elements/dropdown/DropdownOption";
import {isObjectEmpty} from "@/utils/globals";
import LogChange from "@/components/partials/reasons/LogChange";
import ExportPresetReason, {EXPORT_PRESET_REASONS} from "../../../models/reasons/ExportPresetReason";
import {TYPE_OF_CHANGE} from "../../../models/reasons/Reason";
import DLink from "@/components/elements/basicUI/DLink";

const reasonOptions = {
  ratio: [
    new RatioReason(0, '-Select reason-'),
    new RatioReason(1, RATIO_REASONS.INTERN_HOURS),
    new RatioReason(2, RATIO_REASONS.INTERNAL_MISTAKE),
    new RatioReason(3, RATIO_REASONS.BURNED_HOURS),
  ],
  exportPreset: [
    new ExportPresetReason(0, '-Select reason-'),
    new ExportPresetReason(12, EXPORT_PRESET_REASONS.INTERN_HOURS),
    new ExportPresetReason(13, EXPORT_PRESET_REASONS.INTERNAL_MISTAKE),
    new ExportPresetReason(14, EXPORT_PRESET_REASONS.BURNED_HOURS),
  ],
  default: [
    new ExportPresetReason(0, '-Select reason-'),
    new ExportPresetReason(12, EXPORT_PRESET_REASONS.INTERN_HOURS),
    new ExportPresetReason(13, EXPORT_PRESET_REASONS.INTERNAL_MISTAKE),
    new ExportPresetReason(14, EXPORT_PRESET_REASONS.BURNED_HOURS),
  ]
};

const changeTitles = {
  ratio: "Aspect ratio",
  exportPreset: "Export preset",
};

export default {
  name: "SpecifyReason",
  components: {
    DLink,
    LogChange,
    DropdownOption,
    Dropdown,
    Modal
  },
  props: {
    // The type of the change (check Reason.js).
    typeOfChange: {
      type: String,
      required: false,
      default: 'default'
    },

    // The new value for this change.
    newValue: {
      type: [String, Number],
      required: false
    },

    // The selected logs from the table.
    logs: {
      type: Array,
      required: false
    }
  },
  emits: ['reason-confirmed'],
  setup(props, {root, emit}) {
    const store = root.$store;

    /** Values **/
    const isCurrentValueDisplayed = props.typeOfChange !== TYPE_OF_CHANGE.EXPORT_PRESET;

    /** Reasons **/
    const reasons = reasonOptions[props.typeOfChange];
    const selectedReason = ref(null);

    /** Modal **/
    const isConfirmationButtonDisabled = computed(() => !selectedReason.value || selectedReason.value?.id === reasonOptions.ratio[0].id);

    /** Confirm reason **/
    function confirmReason() {
      emit('reason-confirmed', selectedReason.value.text);
    }

    return {
      TYPE_OF_CHANGE,
      isObjectEmpty,
      changeTitle: changeTitles[props.typeOfChange],

      /** Values **/
      isCurrentValueDisplayed,

      /** Reasons **/
      reasons,
      selectedReason,

      /** Modal **/
      isConfirmationButtonDisabled,

      /** Confirm reason **/
      confirmReason
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.variables";
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/layout.shared";

.specify-reason-wrapper {
  .log-changes {
    max-height: rem(300);
    overflow-x: auto;
  }

  .reason-dropdown {
    margin-bottom: rem(40);
    margin-top: rem(15);
  }

  .placeholder {
    color: var(--gray-light);
    font-family: "Open Sans";
    font-size: rem(16);
    line-height: rem(22);
  }

  .option-placeholder {
    color: var(--black-light);
  }
}

// Log change
$box-color: #ECECEC;

.log-change {
  &:not(:last-child) {
    margin-bottom: rem(16);
  }

  .log-info {
    align-items: center;
    display: flex;
    margin-bottom: rem(13);

    .log-link {
      flex-shrink: 0;
      margin-right: rem(8);
    }
  }

  .change-box {
    align-items: center;
    background-color: $box-color;
    border-radius: rem(6);
    display: inline-flex;
    padding: rem(9) rem(16);

    .change-title {
      margin-right: rem(10);
      opacity: 0.6;
    }

    .arrow {
      height: rem(15);
      margin-right: rem(2);
      width: rem(15);
    }

    .change-value {
      color: var(--black-light);
      font-weight: bold;
    }
  }
}
</style>
