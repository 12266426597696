<template>
  <div :class="{'selected': isSelected}" class="tag">
    <span class="tag-name">{{ name }}</span>
    <img :src="icon" class="tag-close" alt=""/>
  </div>
</template>

<script>
export default {
  name: "Tag",
  props: {
    name: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false
    },
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/base.dropdown";

.tag {
  @include hover-active-pointer();
  align-items: center;
  border: rem(1) solid var(--gray-light-02);
  border-radius: rem(13);
  display: flex;
  padding: rem(1) rem(8);

  &-name {
    color: var(--gray-light-03);
    font-size: rem(14);
    line-height: rem(19);
  }

  &-close {
    height: rem(14);
    margin-left: rem(8);
    width: rem(14);
  }

  &:not(:last-child) {
    margin-right: rem(8);
  }
}

.selected {
  background-color: var(--gray-main);
  border: none;

  .tag-name {
    color: var(--black-light);
  }
}
</style>
