<template>
  <div ref="root" class="observer"/>
</template>

<script>
import {computed, ref, onUnmounted} from '@vue/composition-api';

export default {
  name: "Observer",
  props: {
    options: {}
  },
  emits: ['intersect', 'hidden'],
  setup(props, {emit}) {
    const observer = ref(null)
    const root = ref(null);

    function initializeObserver() {
      const options = props.options || {};
      observer.value = new IntersectionObserver(([entry]) => {
        if (entry && entry.isIntersecting) {
          emit('intersected', true);
        } else {
          emit('intersected', false);
        }
      }, options);
      observer.value.observe(root.value);
    }

    onUnmounted(() => {
      observer.value.disconnect();
    })

    return {
      root,
      initializeObserver
    }
  }
}
</script>
