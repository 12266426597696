import {nextTick, ref} from "@vue/composition-api";

export default function getObserverHelpers() {
  const observerOptions = ref(null);

  function initializeObserver(reference) {
    nextTick(() => reference.value.initializeObserver());
  }

  return {
    observerOptions,
    initializeObserver
  }
}