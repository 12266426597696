<template>
  <div v-if="isDropdown"
       @click="toggleOptions"
       :class="[{'active-preset-dropdown': areOptionsDisplayed}, {'edit-disabled': !isEditEnabled}]"
       :id="boxId"
       class="export-preset-box">

    <!-- Selected preset -->
    <p :id="boxPlaceholderId" class="selected placeholder">{{ selectedPreset.text }}</p>

    <!-- Preset selection -->
    <div v-if="areOptionsDisplayed" :id="panelId" class="option-list">
      <div v-for="option in presets" :key="option.id" @click="specifyReason(option)"
           :class="{'selected': selectedPreset.id === option.id}" class="option">
        {{ option.text }}

        <!-- Tooltip -->
        <div class="option-tooltip">
          <img src="../../../assets/icons/svg/ic_info_secondary.svg" class="info-icon" alt=""/>
          <p v-html="option.tooltip" class="tooltip-text"></p>
        </div>
      </div>
    </div>
  </div>

  <div v-else class="export-preset-box preset-list">
    <div v-for="option in presets"
         :key="option.id"
         @click="specifyReason(option)"
         :class="{'selected': selectedPreset.id === option.id}" class="option">
      {{ option.text }}
    </div>

  </div>
</template>

<script>
import {ref, watch} from "@vue/composition-api";
import {ADJUST_EXPORT_PRESETS} from "../../../store/order/actions";
import {EXPORT_PRESETS} from "@/models/order/Worklog";
import {addListener, hasParentWithMatchingSelector, removeListener} from "@/utils/globals";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";

export default {
  name: "ExportPreset",
  components: {},
  props: {
    isDropdown: {
      type: Boolean,
      required: false,
      default: true
    },
    selected: {},
    isEditEnabled: {
      type: Boolean,
      required: false,
      default: true
    },

    // The key of the cell, which will be used to know which cell is editable.
    cellKey: {
      type: String,
      required: false
    },
    rowIds: {
      type: Array,
      required: false
    },
    contentId: {
      type: [Number, String],
      required: false
    }
  },
  emits: ['preset-updated', 'on-active-edit', 'on-inactive-edit'],
  setup(props, {root, emit}) {
    const store = root.$store;

    // Define the preset options.
    const presets = ref([
      {
        id: EXPORT_PRESETS.INCLUDE_HOURS,
        text: 'Include hours',
        tooltip: 'Show this log in the export and include the hours in the calculation'
      },
      {
        id: EXPORT_PRESETS.EXCLUDE_HOURS,
        text: 'Exclude hours',
        tooltip: 'Show this log in the export but exclude the hours from the calculation'
      },
      {
        id: EXPORT_PRESETS.EXCLUDE_LOG,
        text: 'Exclude log',
        tooltip: 'Do not show this log in the export, not include the hours in the calculation'
      },
    ]);

    /** Emits **/
    function presetUpdated() {
      emit('preset-updated');
    }

    function specifyReason(preset) {
      selectedPreset.value = preset;
      emit('specify-reason', preset);
    }

    // Emitted when the dropdown opens.
    function onActiveEdit() {
      emit('on-active-edit', {cellKey: props.cellKey, rowId: props.rowIds[0]});
    }

    // Emitted when the dropdown closes.
    function onInactiveEdit() {
      emit('on-inactive-edit', {cellKey: null, rowId: props.rowIds[0]});
    }

    /** Select **/
    const selectedPreset = ref({});

    // Set the selected preset based on the incoming value.
    if (props.selected) {
      setSelectedPresetBasedOnProp();
    }

    function setSelectedPresetBasedOnProp() {
      selectedPreset.value = presets.value.find(preset => preset.id === props.selected);
    }

    // Make sure to listen to any updates.
    watch(() => props.selected, (newVal) => {
      if (newVal) {
        setSelectedPresetBasedOnProp();
      }
    })

    function selectPreset(reason) {
      // Before saving the selected preset, the user needs to specify the reason for this change.
      return adjustExportPresets(reason).then(response => {
        if (response) {
          // Display success toast.
          ToastHandler.addNew(TOAST_CONTENTS.PRESET_EDITED);

          // Inform the parent that the export preset has been successfully updated.
          presetUpdated();
        }
      });
    }

    function adjustExportPresets(reason = "") {
      // If the rowIds array is not empty, we
      const presets = props.rowIds.map(row => {
        return {
          id: row,
          exportPreset: selectedPreset.value.id
        }
      });
      return store.dispatch(ADJUST_EXPORT_PRESETS, {logs: presets, reason});
    }

    /** Toggle **/
    const areOptionsDisplayed = ref(false);

    function toggleOptions() {
      if (!props.isEditEnabled) {
        return;
      }
      areOptionsDisplayed.value = !areOptionsDisplayed.value;

      // If the dropdown option list is displayed, the click event should be listened to.
      setClickListener();

      // If the edit is active, the cell of the row should be selected (bordered cell), so we need to inform the parent.
      if (areOptionsDisplayed.value) {
        onActiveEdit();
      } else {
        onInactiveEdit();
      }
    }

    /** UI controls **/
    const boxId = `${props.contentId}`;
    const panelId = `${props.contentId}Panel`;
    const boxPlaceholderId = `${props.contentId}Placeholder`;

    function setClickListener() {
      // If the search results panel is open, we need to listen to the clicks of the user in order to keep open or close the search results and make the input inactive.
      areOptionsDisplayed.value ? addListener('click', clickEvent) : removeListener('click', clickEvent);
    }

    const clickEvent = (event) => {
      const parentNode = event.target.parentNode; // Get the parent node of the clicked element.
      const isResultItem = parentNode.classList.contains('option'); // Check if the user clicked on a result item.

      const clickedOnBox = !hasParentWithMatchingSelector(event.target, `#${boxId}`);
      const clickedOnPanel = !hasParentWithMatchingSelector(event.target, `#${panelId}`);

      // If the user clicked on an item, the panel or the toggle, then the panel should remain visible
      if (!isResultItem && clickedOnPanel && clickedOnBox) {
        // If the new clicked item is another toggle, then the we should not reset the table's active cell.
        // if (!event.target.id.includes('exportPreset')) {
        toggleOptions();
        // }
      }
    }

    return {
      presets,

      /** Emits **/
      specifyReason,

      /** Select **/
      selectedPreset,
      selectPreset,

      /** Toggle **/
      areOptionsDisplayed,
      toggleOptions,

      /** UI controls **/
      boxId,
      panelId,
      boxPlaceholderId,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/base.dropdown";

.export-preset-box {
  @include hover-active-pointer;
  position: relative;

  .option-list {
    @include position-elevation(absolute, $top: calc(100% + 8px), $right: 0, $left: 0, $elevation: 1);
    background-color: white;
    border: rem(1) solid var(--gray-light-04);
    border-radius: rem(4);
    padding: rem(8);
  }

  .option {
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: rem(8);

    &:not(:last-child) {
      margin-bottom: rem(8);
    }

    &.selected {
      border-radius: rem(5);
      background-color: var(--gray-main);
      font-size: rem(16);
      font-weight: bold;
      line-height: rem(17);
    }

    &:hover {
      .option-tooltip {
        display: block;
      }
    }
  }

  /** Dropdown option's tooltip **/
  .option-tooltip {
    background-color: white;
    border-radius: 50%;
    display: none;
    position: relative;

    .info-icon {
      height: rem(16);
      width: rem(16);
    }

    &:hover {
      .tooltip-text {
        display: block;
      }
    }

    .tooltip-text {
      @include position(absolute, $top: 50%, $left: rem(25));
      background-color: var(--black-light);
      border-radius: rem(5);
      box-shadow: 2px 4px 8px 0 rgba(0, 0, 0, 0);
      color: white;
      display: none;
      font-size: rem(14);
      letter-spacing: 0;
      line-height: rem(16);
      padding: rem(10);
      text-align: center;
      transform: translateY(-50%);
      width: rem(210);

      &::before {
        @include position(absolute, $top: 50%, $left: rem(-14));
        border-color: transparent transparent var(--black-light) transparent;
        border-style: solid;
        border-width: 0 15px 15px 15px;
        border-radius: rem(8);
        content: "";
        height: 0;
        transform: translateY(-50%) rotate(-90deg);
        width: 0;
        z-index: 12;
      }
    }
  }
}

.edit-disabled {
  @include on-hover {
    cursor: default;
  }
}

/** Active dropdown **/
.active-preset-dropdown {
  border-radius: rem(3);
  box-shadow: inset 0 rem(1) rem(3) 0 rgba(255, 255, 255, 0);

  .dropdown-placeholder {
    color: var(--black-light);
  }
}

/** Styling for when the preset is not a dropdown (just a simple list) **/
.preset-list {
  .option {
    padding-right: 0;
    padding-left: 0;
  }
}
</style>
