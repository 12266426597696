import Reason from "@/models/reasons/Reason";

export const EXPORT_PRESET_REASONS = {
  INTERN_HOURS: 'Intern hours',
  INTERNAL_MISTAKE: 'Internal mistake',
  BURNED_HOURS: 'Burned hours',
}

export default class ExportPresetReason extends Reason {
  constructor(id, text) {
    super(id, text);
  }

  transitionText(from, to) {
    return `Export preset  ${from}-> ${to}`;
  }
}
