<template>
  <div @click="enableEdit()"
       :id="editableCellId"
       :class="[{'active': isContentEditable}, {'edit-disabled': !isEditEnabled}]"
       class="editable-cell">
    <p :id="`content-${contentId}`" class="editable-area">{{ text }}</p>

    <!-- Cell actions -->
    <div v-if="isContentEditable" class="cell-actions">
      <img @click="cancelEdit()" src="../../../assets/icons/svg/ic_close_primary.svg" class="icon" alt="cancel"/>
      <img @click="saveContent()" src="../../../assets/icons/svg/ic_check_success.svg" class="icon" alt="save"/>
    </div>
  </div>
</template>

<script>
import {computed, onMounted, ref} from "@vue/composition-api";
import {hasParentWithMatchingSelector, stopPropagation} from "@/utils/globals";

export default {
  name: "EditableTableCell",
  props: {
    cellKey: {
      type: String,
      required: true
    },
    rowData: {},
    // rowId: {
    //   type: String,
    //   required: true
    // },
    // text: {
    //   type: [String, Number],
    //   required: true
    // },
    contentId: {
      type: [Number, String],
      required: true
    },
    isEditEnabled: {
      type: Boolean,
      required: false,
      default: true
    },
  },
  emits: ['save', 'on-active-edit', 'on-inactive-edit'],
  setup(props, {emit}) {
    let editableContent = ref(null);
    const isContentEditable = ref(false);
    const editableCellId = `editableCell${props.contentId}`;

    const rowId = computed(() => props.rowData.id);
    const text = computed(() => props.rowData[props.cellKey]);

    onMounted(() => {
      // Get the HTML element.
      editableContent.value = document.getElementById(`content-${props.contentId}`);

      // Listen to the enter key, so we can save the content.
      listenToEnter();
    });

    function toggleEditableContent(value) {
      isContentEditable.value = value;
      editableContent.value.contentEditable = isContentEditable.value.toString();
    }

    // TODO: Enable/disable emits can be merged into one (passing null or the data).
    /** Enable content edit **/
    function enableEdit() {
      if (!props.isEditEnabled) {
        return;
      }

      // Let the parent know that the cell is now editable.
      emit('on-active-edit', {cellKey: props.cellKey, rowId: rowId.value});

      // Enable editing on the content.
      toggleEditableContent(true);

      // Focus on the element so the user can immediately start typing.
      editableContent.value.focus();

      // Check if the user clicks outside the editable cell.
      listenToUserClick();
    }

    /** Enable content edit **/
    function disableEdit() {
      // Let the parent know that the cell is not editable anymore.
      emit('on-inactive-edit', {cellKey: null, rowId: rowId.value});

      // Disable editing on the content.
      toggleEditableContent(false);

      // Check if the user clicks outside the editable cell.
      removeUserClickListener();
    }

    /** Save edit **/
    function saveContent() {
      // Make sure that only the saveContent function is executed.
      stopPropagation();

      // Get the added content.
      const content = editableContent.value.innerText;

      // Emit the change to the parent.
      emit('save', {content});

      // Disable editing on the content.
      disableEdit();
    }

    /** Listeners **/
    function listenToEnter() {
      editableContent.value.addEventListener("keyup", enterListener);
    }

    function removeEnterListener() {
      editableContent.value.removeEventListener("keyup", enterListener);
    }

    function enterListener(event) {
      // Upon enter click, the content should be saved.
      if (event.key === 'Enter') {
        saveContent();
        removeEnterListener();
      }
    }

    /** Cancel edit **/
    function cancelEdit() {
      stopPropagation(); // Make sure that only the cancelEdit function is executed.
      editableContent.value.innerText = text.value; // Revert the content to the original.
      disableEdit(); // Disable editing on the content.
    }

    /** User clicks **/
    function listenToUserClick() {
      document.addEventListener('click', userClickEvent);
    }

    function removeUserClickListener() {
      document.removeEventListener('click', userClickEvent);
    }

    function userClickEvent(event) {
      const clickedOnAChild = hasParentWithMatchingSelector(event.target, `#${editableCellId}`);
      const clickedOnEditableCellWrapper = event.target.id === editableCellId;

      // Check if the user clicked outside the editable area.
      if (!(clickedOnAChild || clickedOnEditableCellWrapper)) {
        cancelEdit();
      }
    }

    return {
      isContentEditable,
      editableCellId,
      text,

      /** Enable content edit **/
      enableEdit,

      /** Cancel edit **/
      cancelEdit,

      /** Save edit **/
      saveContent,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";

.editable-cell {
  align-items: center;
  caret-color: var(--red-main);
  display: flex;
  flex-wrap: nowrap;

  // On hover
  @include on-hover {
    cursor: pointer;
  }

  // Area
  .editable-area {
    flex-grow: 1;

    &:focus {
      outline: 0 solid transparent;
    }
  }

  &.edit-disabled {
    @include on-hover {
      cursor: default;
    }
  }
}

// Cell actions
.cell-actions {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: rem(15);

  .icon {
    height: rem(24);
    width: rem(24);

    &:not(:last-child) {
      margin-right: rem(8);
    }

    &:hover {
      cursor: pointer;
    }
  }
}

// Custom editable cell styles
.editable-cell-ratio {

  &.active {
    flex-direction: column;
    margin-left: rem(-8); // The parent cell has a bigger padding than desired.
    margin-right: rem(-8); // The parent cell has a bigger padding than desired.

    .editable-area {
      padding: 0 rem(10);
      text-align: right;
      width: 100%;
    }

    .cell-actions {
      margin-left: 0;
      margin-top: rem(10);
    }
  }
}
</style>
