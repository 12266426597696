<template>
  <div class="date-range-wrapper">
    <p v-if="hasTitle" class="filter-title">Date range</p>

    <div class="pickers-box">
      <BasicDatePicker @value-logs="(input) => setDate('startDate', input)"
                       :value-attr="startValue"
                       :to="dates.endDate"
                       :is-read-only="true"
                       data-cy="from-date-input"
                       id="start"
                       placeholder-attr="DD-MM-YYYY"
                       class="date-input"/>

      <BasicDatePicker @value-logs="(input) => setDate('endDate', input)"
                       :value-attr="endValue"
                       :from="dates.startDate"
                       :is-read-only="true"
                       data-cy="from-date-input"
                       id="end"
                       placeholder-attr="DD-MM-YYYY"
                       class="date-input"/>
    </div>
  </div>
</template>

<script>
import {ref} from "@vue/composition-api"
import BasicDatePicker from "@/components/elements/BasicDatePicker";

export default {
  name: "DateRange",
  components: {
    BasicDatePicker
  },
  props: {
    hasTitle: {
      type: Boolean,
      required: false,
      default: true
    },
    startValue: {},
    endValue: {},
  },
  emit: ['date-selected'],
  setup(props, {root, emit, parent}) {

    const dates = ref({startDate: "", endDate: ""});

    // In case there are initial value passed.
    // if (props.startValue) {
    //   setDate('startDate', props.startValue);
    // }
    // if (props.endValue) {
    //   setDate('endDate', props.endValue);
    // }

    function setDate(property, data) {
      dates.value[property] = data;

      // Inform the parent only after both values have been added.
      const isComplete = Object.keys(dates.value).every(key => dates.value[key] !== "");
      if (isComplete) {
        // Inform the parent about the new dates.
        parent.$emit('date-selected', dates.value);
        emit('date-selected', dates.value);
      }
    }

    function clear() {
      Object.keys(dates.value).map(key => {
        dates.value[key] = "";
      });

      // Inform the parent about the new dates.
      parent.$emit('date-selected', dates.value);
      emit('date-selected', dates.value);
    }

    return {
      dates,
      setDate,
      clear
    }
  }
}
</script>


<style scoped lang="scss">
@import "../../../assets/css/base.mixins";

.date-range-wrapper {

  .pickers-box {
    display: flex;
    position: relative;

    .date-input {
      height: rem(52);
      width: 50%;
    }


    &::v-deep .date-range-wrapper, &::v-deep .picker-input {
      height: 100%;
      width: 100%;
    }

    &::v-deep .ratio-date-picker input {
      width: 100%;
    }
  }
}
</style>
