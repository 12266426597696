<template>
  <div>

    <!-- Includes the title, chip and the hour information. -->
    <div class="content-top">
      <ProcessedOrderInformation/>
    </div>

    <!-- Divider line -->
    <div class="divider-line"/>

    <!-- Includes bulk actions -->
    <AvailableActions v-if="worklogs.length" :logs="selectedRows" @logs-assigned="refreshTableData"/>

    <!-- Search and filter bar -->
    <div class="search-with-filters">
      <SearchInputField @search="performSearch"
                        :include-suggestions="false"
                        :result-count="null"
                        placeholder="Search for an hour log"
                        id="hourLogs"
                        class="search"/>
      <DateRange @date-selected="onDateSelection"
                 :has-title="false"
                 :start-value="getDateXDaysAgo(28)"
                 :end-value="getToday()"
                 class="date-filters"/>
      <FiltersButton @toggle-filters="toggleFilters" :is-active="areFiltersDisplayed" class="filters-button"/>
    </div>
    <Filters v-show="areFiltersDisplayed"
             :has-date-picker="false"
             @filters-changed="getUnassignedLogs"
             class="filters"/>

    <!-- No data indication -->
    <NoRecordsYet v-if="worklogs.length === 0 && !FilterHandler.areFiltersOrSearchActive"
                  @create="startAssigningHours"
                  :button-icon="require('../../assets/icons/svg/ic_next_active_white.svg')"
                  title="Start assigning hours"
                  text="These hours won’t be included in the remaining hours calculation.<br/>Start by assiging logs from the Jira import."
                  button-text="Start assigning"/>

    <!-- Logs table -->
    <div class="table-wrapper">
      <CustomTable :options="tableOptions"
                   :selected-rows="selectedRows"
                   :bordered-cell="editableCellHandler.cell"
                   :table-data="worklogs"
                   class="processed-logs-table floating-table">
        <!-- Head -->
        <template #headcheckbox>
          <BasicCheckbox id="checkbox-head"
                         :is-checkbox-checked="areAllCheckboxesChecked(worklogs.length)"
                         :callback="() => selectAll(worklogs)"/>
        </template>

        <!-- Data -->
        <template #checkbox="props">
          <BasicCheckbox :id="`checkbox-${props.rowData.id}`"
                         :is-checkbox-checked="isCheckboxChecked(props.rowData.id)"
                         :callback="() => selectRow(props.rowData)"/>
        </template>
        <template #createdAt="props">
          {{ props.rowData.date }}
        </template>
        <template #employee="props">
          <EmployeeEditableCell v-if="props.rowData.employee.displayName"
                                @save="({employeeId}) => editEmployee(employeeId, props.rowData)"
                                @on-active-edit="updateActiveEditableCell"
                                @on-inactive-edit="updateActiveEditableCell"
                                :row-id="props.rowData.id"
                                :text="props.rowData.employee.displayName"
                                :content-id="`name-${props.rowData.employee.spacelessDisplayName}`"/>
        </template>
        <template #issueKey="props">
          <!-- If the link is not present, the issue key is a simple text. -->
          <DLink v-if="props.rowData.issueLink" :text="props.rowData.issue.key" :link="props.rowData.issueLink"/>
          <span v-else>{{ props.rowData.issueKey }}</span>
        </template>
        <template #summary="props">
          <span>{{ props.rowData.issue.summary }}</span>
        </template>
        <template #comment="props">
          <EditableTableCell @save="({content}) => editComment(content, props.rowData)"
                             @on-active-edit="updateActiveEditableCell"
                             @on-inactive-edit="updateActiveEditableCell"
                             cell-key="comment"
                             :row-data="props.rowData"
                             :content-id="`comment-${props.rowData.id}`"/>
        </template>
        <template #hours="props">
          <p class="hours-cell">{{ props.rowData.hours }}</p>
        </template>
      </CustomTable>
    </div>
    <!--    <p v-if="worklogs.length === 0 && FilterHandler.areFiltersOrSearchActive" class="no-results-table-text">-->
    <!--      There are no results.-->
    <!--    </p>-->

    <!-- Export modal -->
<!--    <ExportProcessedHours v-if="isExportModalDisplayed"-->
<!--                          @close="toggleExportModal"-->
<!--                          :logs="selectedRows.length > 0 ? selectedRows : worklogs"/>-->
  </div>
</template>

<script>
import NoRecordsYet from "@/components/partials/NoRecordsYet";
import ChipOption, {CHIP_STYLES} from "@/models/ChipOption";
import ProcessedOrderInformation from "@/components/partials/processedOrder/ProcessedOrderInformation";
import getProject from "@/composables/projects/getProject";
import DButton from "@/components/elements/DButton";
import BulkActions from "@/components/partials/bulkActions/BulkActions";
import SectionTitleWithIcon from "@/components/elements/SectionTitleWithIcon";
import Observer from "@/components/elements/Observer";
import AvailableActions from "@/components/partials/order/details/AvailableActions";
import CustomTable from "@/components/partials/table/CustomTable";
import BasicCheckbox from "@/components/elements/BasicCheckbox";
import initializeProcessedOrderTable from "@/utils/tables/getProcessedOrdersTable";
import {getCheckboxHelpers} from "@/utils/controllers/getCheckboxHelpers";
import {GET_UNASSIGNED_LOGS} from "@/store/project/actions";
import {computed, ref} from "@vue/composition-api";
import EditableTableCell from "@/components/partials/table/EditableTableCell";
import {EDIT_LOG} from "@/store/log/actions";
import EmployeeEditableCell from "@/components/partials/table/EmployeeEditableCell";
import {EditableCellHandler} from "@/utils/helpers/editableCellHelpers";
import DLink from "@/components/elements/basicUI/DLink";
import Toast from "@/components/elements/Toast";
import {ROUTE_NAMES_MANAGE_LOGS} from "@/router/modules/manageLogs";
import ToastHandler from "@/utils/handlers/toastHandler/ToastHandler";
import {TOAST_CONTENTS} from "@/utils/handlers/toastHandler/ToastContents";
import SearchInputField from "@/components/partials/search/SearchInputField";
import DateRange from "@/components/partials/filters/DateRange";
import {getDateXDaysAgo, getToday} from "@/utils/dateManipulation";
import FiltersButton from "@/components/partials/filters/FiltersButton";
import Filters from "@/components/partials/filters/Filters";
import FilterHandler from "@/utils/filters/FilterHandler";
import {onUnmounted} from "@vue/composition-api";

export default {
  name: "OrderProcessed",
  components: {
    Filters,
    FiltersButton,
    DateRange,
    SearchInputField,
    Toast,
    DLink,
    EmployeeEditableCell,
    EditableTableCell,
    BasicCheckbox,
    CustomTable,
    AvailableActions,
    Observer,
    SectionTitleWithIcon,
    BulkActions,
    DButton,
    ProcessedOrderInformation,
    NoRecordsYet
  },
  setup(props, {root}) {
    const store = root.$store;
    const router = root.$router;
    const route = root.$route;

    // Get URL params.
    const {projectId} = route.params;

    /** Search **/

    function performSearch(text) {
      FilterHandler.setFilter('text', text);
      getUnassignedLogs();
    }

    /** Filters - Date **/
    function onDateSelection({startDate, endDate}) {
      FilterHandler.setFilter('startDate', startDate);
      FilterHandler.setFilter('endDate', endDate);

      // If the values are empty, only the values should be set.
      if (!FilterHandler.areBothDatesFilled) {
        return false;
      }
      getUnassignedLogs();
    }

    /** Filters **/
    const areFiltersDisplayed = ref(false);

    function toggleFilters() {
      areFiltersDisplayed.value = !areFiltersDisplayed.value;
    }

    /** Logs **/
    getUnassignedLogs({});
    const unassignedLogs = computed(() => store.getters.getUnassignedLogs);
    const worklogs = computed(() => unassignedLogs.value ? unassignedLogs.value.workLogs : []);

    async function editComment(content, row) {
      // Update the comment of the row with the new comment.
      row.comment = content;

      // Edit the log based on the new row data.
      const edited = await editLog(row);
      if (edited) {
        refreshTableData();
        ToastHandler.addNew(TOAST_CONTENTS.COMMENT_EDITED)
      }
    }

    async function editEmployee(employeeId, row) {
      // Set the new employee id.
      row.setEmployeeId(employeeId);

      // Edit the log based on the new row data.
      const edited = await editLog(row);
      if (edited) {
        refreshTableData();
        ToastHandler.addNew(TOAST_CONTENTS.EMPLOYEE_EDITED)
      }
    }

    function getUnassignedLogs() {
      return store.dispatch(GET_UNASSIGNED_LOGS, {
        projectId,
        searchedText: FilterHandler.getFilter('text'),
        employees: FilterHandler.getFilter('employeeIds'),
        issues: FilterHandler.getFilter('issueIds'),
        dateData: {startDate: FilterHandler.getFilter('startDate'), endDate: FilterHandler.getFilter('endDate')},
        departmentData: FilterHandler.getFilter('departments'),
        onlyProcessed: 1
      });
    }

    /** Edit log **/
    function editLog(data) {
      // The API call requires all the below properties (even if they have not been edited).
      const payload = {
        "employeeId": data.employeeId,
        "issueId": data.issueId,
        "comment": data.comment,
        "ratio": data.ratio,
        "logId": data.id,
        "excludeReason": data.excludeReason,
      }
      return store.dispatch(EDIT_LOG, payload);
    }

    /** Project's details **/
    const {getProjectDetails} = getProject();
    getProjectDetails(projectId);

    /** No data indicator **/
    function startAssigningHours() {
      router.push({name: ROUTE_NAMES_MANAGE_LOGS.UNASSIGNED_LOGS});
    }

    /** Table **/
    const {tableOptions} = initializeProcessedOrderTable();

    function refreshTableData() {
      // Get updated data.
      getUnassignedLogs();

      // Reset
      resetRows();
    }

    onUnmounted(() => {
      FilterHandler.reset();
    })

    /** Checkbox control **/
    const {
      selectedRows,
      selectAll,
      selectRow,
      isCheckboxChecked,
      areAllCheckboxesChecked,
      resetRows
    } = getCheckboxHelpers();

    /** Editable cells **/
    const editableCellHandler = ref(new EditableCellHandler());

    function updateActiveEditableCell(data) {
      editableCellHandler.value.addCell(data);
    }

    /** Export **/
    // const isExportModalDisplayed = ref(false);
    //
    // function toggleExportModal() {
    //   isExportModalDisplayed.value = !isExportModalDisplayed.value;
    // }

    return {
      chipOption: new ChipOption('Non billable', CHIP_STYLES.LIGHT_GRAY),
      getToday,
      getDateXDaysAgo,

      /** Search **/
      performSearch,

      /** Filters - Date **/
      FilterHandler,
      onDateSelection,

      /** Filters **/
      areFiltersDisplayed,
      toggleFilters,

      /** Logs **/
      getUnassignedLogs,
      unassignedLogs,
      worklogs,
      editComment,
      editEmployee,

      /** No data indicator **/
      startAssigningHours,

      /** Table **/
      tableOptions,

      /** Checkbox control **/
      selectedRows,
      selectAll,
      selectRow,
      isCheckboxChecked,
      areAllCheckboxesChecked,

      /** Editable cells **/
      editableCellHandler,
      updateActiveEditableCell,

      /** Export **/
      // isExportModalDisplayed,
      // toggleExportModal,

      refreshTableData,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../assets/css/order/base.order-information";

// Top
.content-top {
}
</style>