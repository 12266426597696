<template>
  <div @click="$emit('toggle-filters')" :class="{'active': isActive}" class="filters-button-wrapper">
    <p class="text">Filters</p>
    <img src="../../../assets/icons/svg/ic_filter_secondary.svg" class="icon" alt=""/>
  </div>
</template>

<script>
export default {
  name: "FiltersButton",
  props: {
    isActive: {
      type: Boolean,
      required: true
    }
  },
  emits: ['toggle-filters']
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";

.filters-button-wrapper {
  align-items: center;
  border: rem(1) solid var(--gray-light-02);
  border-radius: rem(6);
  display: flex;
  flex-wrap: nowrap;
  padding: rem(14) rem(20);

  .text {
    font-size: rem(16);
  }

  .icon {
    height: rem(24);
    margin-left: rem(14);
    width: rem(24);
  }

  &.active {
    background-color: var(--gray-light-02);
  }

  &:hover {
    cursor: pointer;
  }
}
</style>
