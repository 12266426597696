<template>
  <div @click="onOptionClick()" :class="{'selected': isSelected}" class="select-order-option">

    <!-- Dot -->
    <div :class="`dot-${option.color}`" class="dot"></div>

    <!-- Text -->
    <p v-if="!customText" class="text">{{ option.type }}</p>
    <p v-else class="text">{{ customText }}</p>

    <!-- Assign button -->
    <div @click="$emit('assign', option)" class="assign-button">
      <span class="text">Assign</span>
      <img src="../../../assets/icons/svg/ic_next_active_white.svg" class="icon" alt=""/>
    </div>
  </div>
</template>

<script>
export default {
  name: "SelectOrderOption",
  props: {
    option: {
      type: Object,
      required: true
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: null
    },
    customText: {
      type: String,
      required: false
    }
  },
  emits: ['clicked', 'assign'],
  setup(props, {emit}) {
    function onOptionClick() {
      if (props.isSelected !== null) {
        emit('clicked', props.option);
      }
    }

    return {
      onOptionClick
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";

.select-order-option {
  align-items: center;
  display: flex;
  padding: rem(15);

  @include hover-active-pointer {
    background-color: var(--gray-main);

    .assign-button {
      display: flex;
    }

    .text {
      font-weight: 600;
    }
  }

  .text {
    text-align: left;
  }
}

.selected {
  background-color: var(--gray-main);
  border-radius: rem(5);

  .text {
    font-weight: bold;
  }

  .assign-button {
    display: flex;
  }
}

// Dot
.dot {
  border-radius: 50%;
  height: rem(8);
  margin-right: rem(10);
  width: rem(8);

  &-purple {
    background-color: var(--purple-light);
  }

  &-light-blue {
    background-color: var(--blue-light);
  }

  &-brown {
    background-color: var(--brown-light);
  }

  &-yellow {
    background-color: var(--yellow-light);
  }

  &-gray {
    background-color: #D6D6D6;
  }

  &-orange {
    background-color: var(--orange-light);
  }
}

// Assign button
.assign-button {
  align-items: center;
  background-color: var(--red-main);
  border-radius: rem(4);
  display: none;
  height: rem(22);
  margin-left: auto;
  padding: 0 rem(8);

  .text {
    color: white;
    font-size: rem(12);
    font-weight: 700;
    margin-right: rem(3);
  }

  .icon {
    height: rem(16);
    width: rem(16);
  }
}
</style>
