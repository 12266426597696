import Reason from "@/models/reasons/Reason";

export const RATIO_REASONS = {
  INTERN_HOURS: 'Intern hours',
  INTERNAL_MISTAKE: 'Internal mistake',
  BURNED_HOURS: 'Burned hours',
}

export default class RatioReason extends Reason {
  constructor(id, text) {
    super(id, text);
  }

  get transitionText() {
    return `Aspect ratio  ${100}-> ${60}`;
  }
}
