<template>
  <div id="searchIssueToggle" class="select-issues filter-element">
    <p class="filter-title">Issue</p>
    <FilterSearchField @search="search"
                       @clear-filter="clearSearchIssuesResults"
                       @display-results="toggleResults"
                       preview-id="issueFilterPreview"
                       results-panel-id="searchIssueList"
                       filter-wrapper-id="searchIssueToggle"
                       :selected-filters="selectedIssues"
                       placeholder="e.g. BIL-5"
                       ref="filterIssueEmployee"
                       id="selectIssue"/>

    <!-- The search results should be displayed if there are search results or selected items. -->
    <div v-show="areResultsDisplayed && (searchResults.length || selectedIssues.length)"
         id="searchIssueList"
         :class="{'has-selected-items': selectedIssues.length}"
         class="search-results dropdown-list">

      <!-- Results - The suggestions as the user is typing. -->
      <SearchResult v-for="issue in searchResults"
                    :key="issue.id"
                    @select="() => selectIssue(issue)"
                    :is-selected="false"
                    :item="issue.searchResultItem"
                    :icon="require('../../../assets/icons/svg/ic_add_dark_grey.svg')"
                    class="result-item"/>

      <!-- Selected -->
      <div v-if="selectedIssues.length" class="selected-items">
        <p class="selected-text">Selected</p>
        <SearchResult v-for="issue in selectedIssues"
                      :key="issue.id"
                      @select="() => selectIssue(issue)"
                      :is-selected="true"
                      :item="issue.searchResultItem"
                      :icon="require('../../../assets/icons/svg/ic_close_secondary.svg')"
                      class="result-item"/>
      </div>
    </div>

  </div>
</template>

<script>
import SearchInputField from "./SearchInputField";
import SearchResult from "./SearchResult";
import {computed, ref} from "@vue/composition-api";
import {addListener, parentContainsElement, removeListener} from "@/utils/globals";
import {SEARCH_JIRA_ISSUES} from "@/store/jira/actions";
import FilterSearchField from "@/components/partials/filters/FilterSearchField";
import {CLEAR_SEARCH_EMPLOYEES_RESULTS, CLEAR_SEARCH_ISSUES_RESULTS} from "@/store/jira/mutations";

export default {
  name: "SearchForJiraIssue",
  components: {
    FilterSearchField,
    SearchResult,
    SearchInputField
  },
  props: {},
  emits: ['issue-selected'],
  setup(props, {root, emit, parent}) {
    const store = root.$store;
    const projectId = root.$route.params.projectId;

    /** Toggling **/
    const filterIssueEmployee = ref(null);
    const areResultsDisplayed = ref(false);

    function toggleResults() {
      areResultsDisplayed.value = !areResultsDisplayed.value;
    }

    function toggleChildResults() {
      filterIssueEmployee.value.toggleResults();
    }


    /** Search **/
    const searchTerm = ref("");

    // The search results should not contain already selected deleted.
    const searchResults = computed(() => jiraIssues.value.filter(issue => selectedIssues.value.findIndex(item => item.id === issue.id) === -1));

    function search(term) {
      searchTerm.value = term;
      searchJiraIssues().then(response => {
        if (response) {
          if (!areResultsDisplayed.value) {
            toggleChildResults();
          }
        }
      })
    }

    /** Selection **/
    const selectedIssues = ref([]);

    function selectIssue(issue) {
      // Check if the issue already exists in the selectedIssues array.
      const index = selectedIssues.value.findIndex(emp => emp.id === issue.id);

      // Add or remove the issue, depending on the index.
      if (index === -1) {
        selectedIssues.value.push(issue);
      } else {
        selectedIssues.value.splice(index, 1);
      }

      // Inform the parent about the new value of the selectedIssues.
      updateParent();
    }

    /** Reset **/
    function resetFilter() {
      selectedIssues.value = [];

      // Inform the parent about the new value of the selectedIssues.
      updateParent();
    }

    function updateParent() {
      parent.$emit('issue-selected', selectedIssues.value);
      emit('issue-selected', selectedIssues.value);
    }

    /** Jira search **/
    const jiraIssues = computed(() => store.getters.getSearchIssuesResults);

    function searchJiraIssues() {
      return store.dispatch(SEARCH_JIRA_ISSUES, {searchTerm: searchTerm.value, projectId});
    }

    function clearSearchIssuesResults() {
      store.commit(CLEAR_SEARCH_ISSUES_RESULTS);
    }

    return {

      /** Toggling **/
      filterIssueEmployee,
      areResultsDisplayed,
      toggleResults,

      /** Search **/
      searchResults,
      search,

      /** Select issue **/
      selectedIssues,
      selectIssue,

      /** Reset **/
      resetFilter,

      /** Jira search **/
      jiraIssues,
      clearSearchIssuesResults,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/base.dropdown";

.select-issues, .select-issues {
  position: relative;

  .search-element {
    height: 100%;
  }
}
</style>
