import {ref} from "@vue/composition-api";

class FilterHandler {
  constructor() {
    this.filters = ref({
      startDate: '',
      endDate: '',
      employeeIds: [],
      issueIds: [],
      departments: [],
      text: '',
    });
  }

  get availableFilters() {
    return this.filters.value;
  }

  setFilter(type, data) {
    this.filters.value[type] = data;
  }

  getFilter(type) {
    return this.filters.value[type];
  }

  reset() {
    this.filters.value = {
      startDate: '',
      endDate: '',
      employeeIds: [],
      issueIds: [],
      departments: [],
      text: '',
    };
  }

  // Dates
  get areBothDatesFilled() {
    return this.availableFilters.startDate !== '' && this.availableFilters.endDate !== ''
  }

  // General
  get areFiltersOrSearchActive() {
    const dates = this.availableFilters.startDate !== '' || this.availableFilters.endDate !== '';
    const employees = this.availableFilters.employeeIds.length > 0;
    const issueIds = this.availableFilters.issueIds.length > 0;
    const departments = this.availableFilters.departments.length > 0;
    const text = this.availableFilters.text !== '';

    return dates || employees || issueIds || departments || text;
  }

  static getInstance() {
    if (this.instance == null) {
      this.instance = new FilterHandler();
    }
    return this.instance;
  }
}

export default FilterHandler.getInstance();