<template>
  <div id="processed-info" :class="{'processed-info-fixed': isInfoFixed}" class="info-wrapper">

    <!-- Page title and relevant chip -->
    <div class="order-information">
      <h1 class="main-title">Processed</h1>
      <Chip :text="chipOption.text"
            :custom-class="chipOption.customClass"
            :icon="chipOption.icon"
            class="processed-chip"/>
    </div>

    <!-- Used to help with the observer -->
    <div v-if="projectDetails" class="info-inner">
      <div class="hour-info-fixed">
        <HourStatistic title="Hours" :amount="projectDetails.totalProcessedHours" class="hour-indications"/>
      </div>
    </div>

    <!-- Hour statistics -->
    <div v-if="projectDetails" class="hour-info">
      <HourStatistic title="Hours" :amount="projectDetails.totalProcessedHours" class="hour-indications"/>
    </div>

    <!-- Observer that checks if the order information is hidden. -->
    <Observer v-if="observerOptions"
              @intersected="updateVisibility"
              :options="observerOptions"
              ref="orderInfoObserver"/>
  </div>
</template>

<script>
import HourStatistic from "@/components/partials/hours/HourStatistic";
import Chip from "@/components/elements/Chip";
import ChipOption, {CHIP_STYLES} from "@/models/ChipOption";
import Observer from "@/components/elements/Observer";
import {computed, ref} from "@vue/composition-api";
import {onMounted} from "@vue/composition-api";
import getObserverHelpers from "@/utils/helpers/observerHelpers";

export default {
  name: "ProcessedOrderInformation",
  components: {
    Observer,
    Chip,
    HourStatistic
  },
  setup(props, {root}) {
    const store = root.$store;

    /** Observer - Used to style the component, when it is scrolled out of the viewport. **/
    const {observerOptions, initializeObserver} = getObserverHelpers();
    const orderInfoObserver = ref(null);
    initializeObserver(orderInfoObserver);

    onMounted(() => {
      observerOptions.value = {
        rootMargin: '0px'
      };
    })

    /** Styling **/
    const isInfoFixed = ref(false);

    function updateVisibility(isVisible) {
      isInfoFixed.value = !isVisible;
    }

    /** Project **/
    const projectDetails = computed(() => store.getters.getSelectedProject); // Get the selected project.

    return {
      chipOption: new ChipOption('Non billable', CHIP_STYLES.LIGHT_GRAY),

      /** Observer **/
      observerOptions,
      orderInfoObserver,

      /** Styling **/
      updateVisibility,
      isInfoFixed,

      /** Project **/
      projectDetails,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/order/base.order-information";

// The wrapper of the entire component.
.info-wrapper {
  align-items: flex-start;
  display: flex;
}

.hour-info {
  align-items: center;
  background-color: var(--gray-main);
  border-radius: rem(10);
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: rem(80);
  padding: rem(25) rem(55);
}

.order-information {
  text-align: left;

  .processed-chip {
    display: inline-flex;
  }
}

.info-inner {
  align-items: flex-start;
  display: flex;
  //transition: all 0.15s ease-out;

  // The fixed part should be visible, since the element is still within the viewport.
  .hour-info-fixed {
    display: none;
  }
}

// Fixed positioning of the information (after scrolling)
.processed-info-fixed {
  .info-inner {
    @include position-elevation(fixed, $top: var(--top-bar-height), $right: 0, $left: 0, $elevation: var(--processed-info-fixed-index));
    align-items: center;
    background-color: white;
    border-top: rem(1) solid rgba(117, 117, 117, 0.2);
    box-shadow: 2px 4px 8px 0 rgba(70, 70, 70, 0.25);
    display: flex;
    justify-content: flex-end;
    padding: rem(6) rem(40);
  }

  // Update the hour statistics' styling, like font-size, etc.
  .hour-info {
    visibility: hidden;
  }

  .hour-info-fixed {
    display: flex;
    padding: rem(5) rem(15);
  }

  .hour-statistic {
    display: inline-flex;

    // Format the title into "Hours: number_of_hours".
    ::v-deep .title-wrapper {
      &::after {
        content: ":";
        font-family: var(--open-sans);
        font-size: rem(14);
        margin-right: rem(4);
      }

      .title-shape {
        height: rem(8);
        margin-right: rem(6);
        width: rem(8);
      }
    }

    ::v-deep .number {
      display: inline-block;
      font-size: rem(14);
      line-height: rem(19);
      margin-top: 0;
    }
  }
}
</style>