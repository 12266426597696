<template>
  <div>
    <!-- Inactive input field (results within the input field) -->
    <div v-show="displayFilterPreview" @click="enableInput()" :id="previewId"
         class="basic-input search-field filter-preview">

      <!-- Filters -->
      <div :class="{'overflowing-wrapper': filtersOverflow}" class="filters-wrapper">
        <div v-for="(filter, index) in selectedFilters"
             :key="filter.id"
             :class="{'filter-chip-overflowing': index + 1 > filtersInPreview}"
             class="selected-filter-chip">
          <p class="text">{{ filter.searchResultItem.name }}</p>
          <img src="../../../assets/icons/svg/ic_close_secondary.svg" class="icon" alt="close"/>
        </div>
      </div>

      <!-- Info and action -->
      <div :id="`infoAction${id}`" class="info-action">

        <!-- If not all the selected filters are shown in the preview, an indication of how many are not shown is displayed to the user. -->
        <span v-if="filtersOverflow" class="more">{{ selectedFilters.length - filtersInPreview }} more</span>
        <img src="../../../assets/icons/svg/ic_dropdown_inactive.svg" class="caret-icon" alt=""/>
      </div>
    </div>

    <!-- Active input field -->
    <div :class="{'input-invisible': !displayInputField}">
      <label :for="id" class="basic-label search-label">
        <img :src="displayedIcon" class="search-icon" alt="search icon"/>
        <input type="text"
               :id="id"
               :name="id"
               :placeholder="placeholder"
               :data-cy="`cy-${id}`"
               :disabled="isDisabled"
               autocomplete="off"
               @input="handleInput"
               @focus="() => updateDisplayedIcon(iconTypes.active)"
               @focusout="() => updateDisplayedIcon(iconTypes.inactive)"
               v-model="input"
               class="basic-input search-field"
               :ref="`searchField${id}`"/>

        <!-- Cancel/Clear search -->
        <img v-if="input"
             @click="clearSearch()"
             src="../../../assets/icons/svg/ic_close_secondary.svg"
             class="clear-search"
             alt="clear"/>
      </label>


    </div>
  </div>
</template>

<script>
import {computed, ref, watch} from "@vue/composition-api";
import {addListener, hasParentWithMatchingSelector, removeListener} from "@/utils/globals";

const iconTypes = {
  active: 'active',
  inactive: 'inactive'
}

let searchTimeout;
const debounce = 500;

export default {
  name: "FilterSearchField",
  props: {
    // Basic
    // The id is used in cases such as ids for child elements and selectors, in case there are more than 1 instances of "FilterSearchField" component.
    id: {
      type: String,
      required: true
    },
    placeholder: {
      type: String,
      required: true
    },

    // Behavior
    isDisabled: {
      type: Boolean,
      required: false,
      default: false
    },
    filterWrapperId: {
      type: String,
      required: true
    },

    // Selected
    selectedFilters: {},
    hasSelectedItems: {
      type: Boolean,
      required: false,
      default: false
    },

    // Results
    resultsPanelId: {
      type: String,
      required: true
    },

    // Filter preview
    previewId: {
      type: String,
      required: true
    }
  },
  emits: ['clear-filter', 'display-results'],
  setup(props, {emit, root}) {

    /** Emits **/
    function clearFilter() {
      emit('clear-filter');
    }

    function search(value) {
      emit('search', value);
    }

    /** Search input **/
    const displayInputField = computed(() => areResultsDisplayed.value || (!areResultsDisplayed.value && props.selectedFilters.length === 0));
    const input = ref('');

    function handleInput(event) {
      clearTimeout(searchTimeout)
      searchTimeout = setTimeout(() => {
        search(input.value);
      }, debounce);
    }

    function clearSearch() {
      input.value = '';
      clearFilter();
    }

    // Used to focus on the input field.
    function focus() {
      document.getElementById(props.id).focus();
    }

    /** Search input icons **/
    const searchIcons = {
      active: require('../../../assets/icons/svg/ic_search_active.svg'),
      inactive: require('../../../assets/icons/svg/ic_search_inactive.svg'),
    };
    const displayedIcon = ref(searchIcons.inactive);

    // The search icon changes, depending on whether the input field is in focus or not.
    function updateDisplayedIcon(type) {
      displayedIcon.value = searchIcons[type];
    }

    /** Results panel **/
    const displayFilterPreview = computed(() => !areResultsDisplayed.value && props.selectedFilters.length > 0);

    /** Toggling **/
    function enableInput() {
      // Show the input field.
      areResultsDisplayed.value = true;

      // Focus on the input field and set the click listeners.
      root.$nextTick(() => {
        focus();
        setClickListener();
      })

      // If there are selected filters, the results panel should be displayed.
      if (props.selectedFilters.length > 0) {
        // Update the parent.
        emit('display-results');

        // If the dropdown option list is displayed, the click event should be listened to.
        setClickListener();
      }
    }

    const areResultsDisplayed = ref(false);

    function toggleResults() {
      // Toggle the result panel.
      areResultsDisplayed.value = !areResultsDisplayed.value;

      // If the search result panel closes, reset the search results and the searched text.
      if (!areResultsDisplayed.value) {
        clearFilter();
        input.value = "";
      }

      // Update the parent.
      emit('display-results');

      // If the dropdown option list is displayed, the click event should be listened to.
      setClickListener();
    }

    /** Click listeners **/
    function setClickListener() {
      // If the search results panel is open, we need to listen to the clicks of the user in order to keep open or close the search results and make the input inactive.
      areResultsDisplayed.value ? addListener('click', clickEvent) : removeListener('click', clickEvent);
    }

    const clickEvent = (event) => {
      const parentNode = event.target.parentNode; // Get the parent node of the clicked element.
      const isResultItem = parentNode.classList.contains('result-item'); // Check if the user clicked on a result item.

      // If the user clicked on a result item, clicked on the toggle or the list result panel then the result panel and input field should remain visible.
      if (!isResultItem && !hasParentWithMatchingSelector(event.target, `#${props.filterWrapperId}`) && !hasParentWithMatchingSelector(event.target, `#${props.resultsPanelId}`)) {
        toggleResults();
      }
    }

    /** Filter preview **/
    const filtersInPreview = ref(0); // Saves the amount of filters that are should be displayed in the preview.

    // Saves if the amount of filters that are displayed in the filter preview are overflowing (do not fit in the wrapper).
    const filtersOverflow = computed(() => props.selectedFilters.length > filtersInPreview.value);

    function calculatePreviewItems() {
      root.$nextTick(() => {
        let filtersWidth = 0;

        // Reset the saved value from the previous time the value was updated.
        filtersInPreview.value = 0;

        // Get the width of the filter preview wrapper.
        const previewWrapper = document.getElementById(props.previewId);
        const previewWrapperOffsetWidth = previewWrapper.offsetWidth;

        // Get the width of the info action wrapper.
        const infoAction = document.getElementById(`infoAction${props.id}`);
        const infoActionWidth = infoAction.offsetWidth;

        // Get the width of the available space, not forgetting the margin-right value of the selected-filter-chip class.
        const availableSpace = previewWrapperOffsetWidth - (infoActionWidth + 8); // 8 = margin-right

        // Get the width of each selected item to calculate how many fit in the wrapper.
        const allFilters = document.querySelectorAll('.selected-filter-chip');

        // For each filter chip, check if it can fit in the filter box.
        allFilters.forEach((element, index) => {

          // if the element can fit in the filter box, make sure to update the number of filtersInPreview and the total width of the displayed filter items.
          if (filtersWidth + element.offsetWidth <= availableSpace) {
            filtersInPreview.value++;
            filtersWidth += element.offsetWidth;
          }
        });
      });
    }

    watch(areResultsDisplayed, (newVal) => {
      // As soon as the results are hidden and there are selected filters, we need to set up the filter preview box.
      if (!newVal && props.selectedFilters.length > 0) {
        calculatePreviewItems();
      }
    })

    return {
      iconTypes,

      /** Search input **/
      displayInputField,
      input,
      handleInput,
      clearSearch,

      /** Icon **/
      searchIcons,
      displayedIcon,
      updateDisplayedIcon,

      /** Results panel **/
      displayFilterPreview,

      /** Toggling **/
      enableInput,
      toggleResults,

      /** Filter preview **/
      filtersInPreview,
      filtersOverflow,
    }
  }
}
</script>

<style scoped lang="scss">
@import "../../../assets/css/base.mixins";
@import "../../../assets/css/forms";
@import "../../../assets/css/filters";

.search-input {
  position: relative;
}

.search-label {
  position: relative;
}

.search-icon {
  @include position(absolute, $top: 50%, $left: rem(15));
  transform: translateY(-50%);
  height: rem(24);
  width: rem(24);
}

.clear-search {
  @include position(absolute, $top: 50%, $right: rem(17));
  @include hover-active-pointer();
  transform: translateY(-50%);
  height: rem(16);
  width: rem(16);
}

.result-indicator {
  @include position(absolute, $top: 50%, $right: rem(56));
  color: var(--gray-light-03);
  font-size: rem(14);
  letter-spacing: 0;
  line-height: rem(22);
  transform: translateY(-50%);
}

.search-field {
  caret-color: var(--red-main);
  height: 100%;
  min-height: rem(42);
  padding-left: rem(49);
}

.search-field-inactive {
  display: flex;
  padding: rem(10);
}

// Input field
.input-invisible {
  position: absolute;
  visibility: hidden;
}

// Filter preview
.filter-preview {
  display: flex;
  height: rem(42);
  padding: rem(10);
  position: relative;
  flex-basis: 100%;

  .filters-wrapper {
    display: flex;
    margin-right: auto;
  }

  .info-action {
    align-items: center;
    display: flex;
    justify-content: center;

    .caret-icon {
      height: rem(24);
      width: rem(24);
    }

    .more {
      font-size: rem(10);
      line-height: rem(22);
      flex-shrink: 0;
      margin-right: rem(10);
    }
  }
}

.overflowing-wrapper {
  overflow: hidden;

  .selected-filter-chip {

    &.filter-chip-overflowing {
      opacity: 0;
    }
  }
}

// Selected items
.selected-items {
  display: flex;
}
</style>
