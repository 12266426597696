// The types of the changes, for which a reason should be specified.
// The reasons are based on the log's corresponding properties (check Log.js or Worklog.js).
export const TYPE_OF_CHANGE = {
  RATIO: 'ratio',
  EXPORT_PRESET: 'exportPreset',
  DEFAULT: 'default'
}

export default class Reason {
  constructor(id, text) {
    this.id = id;
    this.text = text;
  }
}
