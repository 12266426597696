<template>
  <a v-if="!routerLink" :href="link" :target="target" class="d-link-base d-link">
    {{ text }}
    <img v-if="icon" :src="icon" class="link-icon" alt=""/>
  </a>
  <router-link v-else :to="routerLink" :active-class="routeActiveClass">
    {{ text }}
  </router-link>
</template>

<script>
export default {
  name: "DLink",
  props: {
    link: {
      type: String,
      required: false,
      default: ''
    },
    text: {
      type: String,
      required: true
    },
    target: {
      type: String,
      required: false,
      default: '_blank'
    },
    icon: {
      type: String,
      required: false
    },

    // Router
    routerLink: {
      type: Object,
      required: false
    },
    routeActiveClass: {
      type: String,
      required: false
    }
  }
}
</script>
<style scoped lang="scss">
@import "../../../assets/css/base.variables";

.d-link {
  color: var(--red-main);
  font-family: var(--open-sans);

  &:hover {
    text-decoration: underline;
  }
}

.d-link-black {
  color: var(--black-light);
  font-family: var(--montserrat);
  font-size: rem(17);
  line-height: rem(20);
}
</style>
