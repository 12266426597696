import {ref} from "@vue/composition-api";

export function getCheckboxHelpers() {
  const selectedRows = ref([]);

  function selectRow(rowData) {
    let exists = selectedRows.value.find(row => row.id === rowData.id);
    exists ? removeRow(rowData) : addRow(rowData);
  }

  function selectAll(rows) {
    if (selectedRows.value.length === rows.length) {
      deselectAll();
      return;
    }
    rows.forEach(dataRow => {
      let exists = selectedRows.value.find(row => row.id === dataRow.id);
      if (!exists) {
        addRow(dataRow);
      }
    });
  }

  function deselectAll() {
    selectedRows.value = [];
  }

  function addRow(row) {
    selectedRows.value.push(row);
  }

  function removeRow(row) {
    selectedRows.value = selectedRows.value.filter(r => r.id !== row.id);
  }

  function resetRows() {
    selectedRows.value = [];
  }

  const isCheckboxChecked = (rowId) => !!selectedRows.value.find(row => rowId === row.id);
  const areAllCheckboxesChecked = (totalCount) => selectedRows.value.length === totalCount;

  return {
    selectedRows,
    selectAll,
    selectRow,
    isCheckboxChecked,
    areAllCheckboxesChecked,
    resetRows
  }
}